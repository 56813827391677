
import React, {Component} from 'react';
import {Segment, Container} from 'semantic-ui-react';
import RecommendedHeader from './RecommendedHeader';
import RecommendedContent from './RecommendedContent';

export default function RecommendedView (props) {
           return <RecommendedContent 
                                size={props.size}
                                cardsPerView={props.cardsPerView}
                                articleKey={props.articleKey}
                                viewed_url={props.viewed_url}/>

}
