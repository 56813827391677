import React, { useRef, useEffect, useState, useContext } from 'react';
import { Modal, Image, Transition, Button, List, Visibility, Loader, Container, Segment, Card, Header, Grid, Icon, Label, Menu } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import getRecommended from '../utils/getRecommended';
import createCards from '../utils/createCards';
import createVideoCards from './utils/createVideoCards';
import getVideo from './utils/getVideo';
import getVideoByToken from './utils/getVideoByToken';
import getCategory from './utils/getCategory';
import SignUp from './SignUp';
import dropdown from './DropDown';
import Tutorial from './Tutorial'; 
import AuthContext  from '../auth-context';
import VibeShakeMenu from './VibeShakeMenu';
import ReactPlayer from 'react-player';
import VideoCommentView from './VideoCommentView'; 
import { getAxiosClient } from './utils/AxiosClient';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

const Text = styled.span`
    h {
      font-family: Verdana, sans-serif; 
      font-size: 1.20em;
      line-height: 1.6;
    }
`;

const FixPlayer = styled.span`
    .react-player > div{
    position: absolute;
    }
`;


const FixContainer = styled.span`
    .ui.container {
        margin-top: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
`;

const HorizontalScroll = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    
    .label {
      flex: 0 0 auto;
    }

`;

const StyleSwiper = styled.span`
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    zIndex:100;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-pagination-bullet-active{
  background-color:#fff;
}

.color-1{
  background-color:#009688;
}
.color-2{
  background-color:#4CAF50;
}
.color-3{
  background-color:#FFEB3B;
}
.color-4{
  background-color:#FF5722;
}
.color-5{
  background-color:#607D8B;
}
`;

function createVideoLink(videoToken, profileToken) {
    return `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/video/${videoToken}/video.mp4`;
};

function createProfileLink(profileToken, hasThumb) {

    if(!hasThumb || !profileToken){
      return '../../../assets/user_placeholder.png';
    }

    return `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/thumbnail.png`;
};

const OFFSET = 1;
const VIDEO_POLL_IDX=6;
export default function VideoView(props) {
    const feedCategory = localStorage.getItem('feedCategoryName');
    const [axiosClient, setAxiosClient] = useState(getAxiosClient());
    const [authed, setAuthed] = useState(localStorage.getItem("authToken"));
    const [blockRefresh, setBlockRefresh] = useState(false);
    const [activeItem, setActiveItem] = useState("all");
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [attempted, setAttempted] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [videoIdx, setVideoIdx] = useState(0);
    const [scrollInfo, setScrollInfo] = useState(false);
    const [scrollIcon, setScrollIcon] = useState(false);
    const [clickedOnce, setClickedOnce] = useState(false);
    const [videoMetadatas, setVideoMetadatas] = useState([]);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [cumulativeIdx, setCumulativeIdx] = useState(0);
    const lastUsername = useRef(null);

    const handleItemClick = function (e,{name}) {
      setActiveItem(name); 
    }

    useEffect( () => {

      if(!swiperInstance)
        return;

      swiperInstance.on("slideChange", () => { 
          setVideoIdx(swiperInstance.realIndex);
      });
    }, [swiperInstance]);

    useEffect( () => {
      if (props.videoInView) {
        (async () => {
        const videoViewMetadataPromise = getVideoByToken(props.videoInView);
        if(props.username && props.username !== lastUsername.current) {
          lastUsername.current = props.username;
          const profileMetadataPromise = axiosClient.get(`profile/${props.username}/video`)
            .then(resp => resp.data.videos);
          const videoViewMetadata = await videoViewMetadataPromise;
          setVideoMetadatas([videoViewMetadata, ...videoMetadatas]);
          const profileMetadatas = await profileMetadataPromise;
          console.log('profileMetadata', profileMetadatas);
          setVideoMetadatas([videoViewMetadata, ...profileMetadatas]);
          return;
        }
        const categoryMetadataPromise = getVideo(activeItem);
        const videoViewMetadata = await videoViewMetadataPromise;
        setVideoMetadatas([videoViewMetadata, ...videoMetadatas]);
        const categoryMetadata = await categoryMetadataPromise;
        setVideoMetadatas([...videoMetadatas, ...categoryMetadata]);
        })();
      } else {
        getVideo(activeItem).then( (videos) => setVideoMetadatas(videos) );
      }
    }, [activeItem]);

    useEffect( () => {

        if(videoIdx < VIDEO_POLL_IDX)
            return;

        getVideo(activeItem, cumulativeIdx + OFFSET).then( (newVideoMetadatas) => {
            setCumulativeIdx(cumulativeIdx + OFFSET);
            setVideoMetadatas([...videoMetadatas.slice(videoIdx - 1, videoMetadatas.length),
                              ...newVideoMetadatas]);
        });

    }, [videoIdx]);


    const videos = () => {

        if (!videoMetadatas.length) {
            return [];
        }
        return videoMetadatas.map( (metadata, idx) => {
                const playCondition = playVideo && videoIdx === idx;
                const profileHasThumb = metadata[2];
                const profileToken = metadata[3];
                const videoToken = metadata[0];
                const videoDesc = metadata[1];
                const username = metadata[4];
                const videoCommentCount = metadata[5];
                return (
                <div key={idx} style={{padding:'0px', margin:'0px'}}>

                <ReactPlayer 
				          height={window.innerHeight}
				          width="100%"
                  onEnded={() => { 
                    if(idx === 0)
                      setScrollInfo(true);
                  }}
                  playing={playCondition}
				  style={{margin:'0', padding:'0'}}
                  className='react-player'
                  playsinline
                  url={createVideoLink(videoToken, profileToken)}
                  volume={1}
                />
            <div
              onClick={() => {
                 setPlayVideo(!playVideo); 
                 setScrollInfo(playCondition && swiperInstance.realIndex === 0);
                }
              }
              style={{
                   display:'flex',
                   justifyContent:'center',
                   position:'absolute', 
                    top:'50%',
                    left:'50%',
                    height:'100%',
                    width:'100%',
                    zIndex:'15',
                    transform:'translate(-50%,-50%)',
                    }}
              >
          { !playCondition ? 
           <Icon 
             link size='huge' color="white" inverted style={{maxHeight:'1.5em', maxWidth:'1.5em', margin:'auto', textShadow:'2px 2px 2px #E03977'}} name='play'
            /> : null
          }
            </div>
      <Transition
        visible={scrollInfo && idx === 0 && !playCondition}
        onShow={() => setScrollIcon(true)}
        onHide={() => setScrollIcon(false)}
      >
            <Segment.Group
              verticalAlign
              onClick={() => setPlayVideo(!playVideo)}
              style={{
                   display:'flex',
                   borderStyle:'none',
                   justifyContent:'center',
                backgroundColor:'rgba(0,0,0,0.25)',
                   position:'absolute', 
                    top:'78%',
                    left:'50%',
                    width:'100%',
                    zIndex:'5',
                    transform:'translate(-50%,-50%)',
                    }}
              >
            <Segment basic textAlign="center">
            <Header size='large' style={{
                color:'white'}}>
             Scroll down for more vlogs
            </Header>
            </Segment>
            <Segment basic textAlign="center" style={{padding:'1em', minHeight:'4.75em'}}>
          { !playCondition ? 
            (<Transition
              animation='bounce'
              duration={1000}
              visible={scrollIcon && !playVideo}
              onComplete={() => setTimeout(() => { if(scrollInfo) setScrollIcon(!scrollIcon) } , 500)}
            >
            <Icon size='huge' color="white" inverted name='angle double down'/>
            </Transition>
            ): null}
            </Segment>
            </Segment.Group>
          </Transition>
            <Segment.Group style={{color:'white', position:'absolute', top:'33%', right:'.05em', zIndex:'20', borderStyle:'none', boxShadow:'none', padding:'.1em', margin:'0'}}>
			<Segment basic textAlign='center' style={{borderStyle:'none'}}>
            <Link as='a' to={{pathname: `/p/${username}`, state:{prevLink: `/v/${videoToken}`}}}>
			<Icon.Group style={{fontSize:"2.66em"}}>
            {/* <Icon link name='user circle outline'/> */}
            <Image link style={{maxHeight:'4em', maxWidth:'4em'}} circular size='tiny' src={createProfileLink(profileToken, profileHasThumb)} />
			<Icon link style={{fontSize:".4em"}} corner='bottom' name='add' color='pink'/>
			</Icon.Group>
            </Link>
			</Segment>
			<Segment basic textAlign='center' style={{borderStyle:'none'}}>
            <Modal
              closeIcon
              trigger={
            <Link as='a'>
			<Icon link style={{fontSize:'2.15em', margin:'auto', color:'white'}} link name='comment alternate' color='white'>
			<p style={{
          	fontFamily: 'Verdana, sans-serif',
            fontWeight: '500',
			fontSize:'.45em'}}>
              {videoCommentCount}
			</p>
			</Icon>
            </Link>}
            >
            <Modal.Content>
              <VideoCommentView videoToken={videoToken} key={Date.now()} axiosClient={axiosClient}/>
            </Modal.Content>
            </Modal>

           
			</Segment>
			<Segment basic textAlign='center' style={{paddingTop:'0', borderStyle:'none'}}>
            <span style={{margin:'auto'}}>
			{dropdown({}, 'share big', '2.15em')}
            </span>
			</Segment>
			</Segment.Group>
       <div 
        style={{
           paddingLeft:'.0em',
           paddingBottom:'1em',
           display:'block',
           width:'66%',
           position:'absolute', 
            left:'0.00em',
            bottom:'3em',
            zIndex:'4'
            }}
       >
       <Container text textAlign='left'>
       <Text>
       <Header as='h3' style={{color:'white'}}>
       {videoDesc} 
       </Header>
       </Text>
       </Container>
       </div>
           </div>
            );
        });
    }
    const params = {
		direction:'vertical',
        pagination: '.swiper-pagination',
        height: window.innerHeight,
        width: window.innerWidth,
        autoHeight: true,
        paginationClickable: true,
		spaceBetween:0,
        parallax: true,
        speed: 600,
    };



    return (<>
			<StyleSwiper>
            <Swiper {...params} getSwiper={(swiperInstance) => setSwiperInstance(swiperInstance)}>
			{[...videos()]}
			<div className="color-2" style={{margin:0, padding:0}}>Slide 2</div>
			<div className="color-3">Slide 3</div>
			<div className="color-4">Slide 4</div>
            </Swiper>
			</StyleSwiper>
      <div style={{
                   display:'flex',
                   borderStyle:'none',
                   justifyContent:'center',
                   margin:'0',
                   position:'absolute', 
                    top:'5%',
                    left:'84.25%',
                    width:'25%',
                    zIndex:'20',
                    transform:'translate(-50%,-50%)',
                    }}>
      <VibeShakeMenu authed={authed}/>
      </div>
            <Menu secondary 
            size='small'
            style={{
                backgroundColor:'rgba(0,0,0,0.43)',
                position:'absolute', 
				padding:'.25em',
				paddingRight:'0',
				paddingLeft:'0',
				margin:'0',
                zIndex:'16',
                left:'0px',
				maxWidth:'100vw',
                right:'0px',
                bottom:'0px',
                borderStyle:'none'
            }}
            >
            <HorizontalScroll>
            <Menu.Item
             name='all'
             active={activeItem == 'all'}
             onClick={handleItemClick}
             >
             <Icon 
                   circular={activeItem == 'all'}  
                   inverted={activeItem == 'all'}  
                   color={activeItem == 'all' ? 'pink' : 'pink'} name='fire' />
            <span style={{color:'white'}}>
            Trending
            </span>
            </Menu.Item>
            <Menu.Item
             name='following'
             active={activeItem == 'following'}
             onClick={handleItemClick}
             >
              <Icon 
                   circular={activeItem == 'following'}  
                   inverted={activeItem == 'following'}  
                    color={activeItem == 'following' ? 'pink' : 'pink'} name='group' />
            <span style={{color:'white'}}>
              Following
            </span>
            </Menu.Item>
            <Menu.Item
             name='vlog'
             active={activeItem == 'vlog'}
             onClick={handleItemClick}
             >
              <Icon 
                   circular={activeItem == 'vlog'}  
                   inverted={activeItem == 'vlog'}  
                color={activeItem == 'vlog' ? 'pink' : 'pink'} name='video' />
            <span style={{color:'white'}}>
              Vlog
            </span>
            </Menu.Item>
            <Menu.Item
             name='entertainment'
             active={activeItem == 'entertainment'}
             onClick={handleItemClick}
             >
              <Icon 
                   circular={activeItem == 'entertainment'}  
                   inverted={activeItem == 'entertainment'}  
                    color={activeItem == 'entertainment' ? 'pink' : 'pink'} name='star' />
            <span style={{color:'white'}}>
              Entertain
            </span>
            </Menu.Item>
            <Menu.Item
             name='current'
             active={activeItem == 'current'}
             onClick={handleItemClick}
             >
            <Icon 
                   circular={activeItem == 'current'}  
                   inverted={activeItem == 'current'}  
                   color={activeItem == 'current' ? 'pink' : 'pink'} name='lightning' />
            <span style={{color:'white'}}>
            Current
            </span>
            </Menu.Item>
            <Menu.Item
             name='cool'
             active={activeItem == 'cool'}
             onClick={handleItemClick}
             >
            <Icon 
                   circular={activeItem == 'cool'}  
                   inverted={activeItem == 'cool'}  
                color={activeItem == 'cool' ? 'pink' : 'pink'} name='flask' />
            <span style={{color:'white'}}>
            Cool
            </span>
            </Menu.Item>
            </HorizontalScroll>
            </Menu>
			</>
            )  
}
