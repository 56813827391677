
export default function getCategory (AxiosClient, id, dateRange=null) {
   let resourcePath = `content/category/${id}`;

   if (dateRange && dateRange !== 'all') {
       resourcePath = `${resourcePath}?date_range=${dateRange}`;
   }

   return AxiosClient.get(resourcePath).then( (response) => {

       if(response.status === 200)
           return response.data.candidates; 

       return [];

   });
}
