import React from 'react';
import { Icon, Popup, Grid, Segment, Card, Image, Dropdown } from 'semantic-ui-react';
import { EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton }  from 'react-share';
import moment from 'moment';

const dropdown = ({urlToken, firstName, lastName}) => (<Dropdown icon='ellipsis vertical'>
                   <Dropdown.Menu direction='right'>
                      <Dropdown.Header content='Share'/>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <TwitterShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/p/${urlToken}`}>
                          <Icon name='twitter' />
                        </TwitterShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <FacebookShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/p/${urlToken}`}>
                          <Icon name='facebook' />
                        </FacebookShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <LinkedinShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/p/${urlToken}`}>
                          <Icon name='linkedin' />
                        </LinkedinShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <EmailShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/p/${urlToken}`}
                           subject={`Check out ${firstName} ${lastName} on intrgr.com`}
                           body={`What do you think?\n\n`}
                           >
                          <Icon name='mail' />
                        </EmailShareButton>
                      </Dropdown.Item>
                   </Dropdown.Menu>
                   </Dropdown>);

const imageUrl = function ({hasThumb, urlToken}) {
    let imageUrl = '../../../assets/user_placeholder.png';
    if (hasThumb) 
       imageUrl = `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${urlToken}/thumbnail.png`;
    return imageUrl;

};

const cardConfig = {
  regular: {
    maxCardTitleWithImage: 100,
    maxCardTitle: 200,
    style: {
      maxHeight:"70vh",
      minWidth:"13em",
      maxWidth:"15em"
    },
  },
  mobileView: {
    maxCardTitleWithImage: 80,
    maxCardTitle: 200,
    style: {
      maxHeight:"48vh",
      minHeight:"26em",
      minWidth:"13em",
    },
  },
};

export default function createProfileCards(profileArray, style="regular") {
  return profileArray.filter((profile) => profile && profile.urlToken).map( (profile, index) => {
  const href = `/p/${profile.urlToken}`;

    const ignore = (<Icon link name='ellipsis vertical'></Icon>);
        return (<Card 
                  link
                  href={href}
                  key={`${profile.urlToken}-${index}`}
                  style={cardConfig[style]}
                >
                <Image 
                  size='large'
                  wrapped
                  ui={false}
                  src={imageUrl(profile)} /> 
                <Card.Content
                  href={href}
  							 >
                  <Card.Header textAlign="center" > 
                   {`${profile.firstName} ${profile.lastName}`}
                  </Card.Header>
                </Card.Content>
                  <Card.Meta>
	                <Segment basic style={{position: 'relative', minWidth:'1em'}}>
                       <span style={{position:'absolute', bottom:'.5em', left:'1em'}}>
                        {`Followed: ${moment(profile.dateFollowed).fromNow()}`}
                        </span>
                        <span style={{position:'absolute', bottom:'.5em', right:'.5em'}}>
                        {dropdown(profile)}
			          </span>
			       </Segment>
                  </Card.Meta>
                <Card.Content extra textAlign="left">
                  <Card.Meta>
                  <div style={{overflow: 'hidden'}}>
                    intrgr.com
                  </div>
                  </Card.Meta>
                </Card.Content>
                </Card>);
  });
}
