import React from 'react';
import { Icon, Popup, Grid, Segment, Card, Image, Dropdown } from 'semantic-ui-react';
import { EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton }  from 'react-share';
import moment from 'moment';

const dropdown = ({url_token, title}) => (<Dropdown icon='ellipsis vertical'>
                   <Dropdown.Menu direction='right'>
                      <Dropdown.Header content='Share'/>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <TwitterShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/a/${url_token}`}>
                          <Icon name='twitter' />
                        </TwitterShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <FacebookShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/a/${url_token}`}>
                          <Icon name='facebook' />
                        </FacebookShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <LinkedinShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/a/${url_token}`}>
                          <Icon name='linkedin' />
                        </LinkedinShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <EmailShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/a/${url_token}`}
                           subject="Thoughts?"
                           body={`${title}\n\n`}
                           >
                          <Icon name='mail' />
                        </EmailShareButton>
                      </Dropdown.Item>
                   </Dropdown.Menu>
                   </Dropdown>);

const cardConfig = { 
  regular: {
    maxCardTitleWithImage: 100,
    maxCardTitle: 200,
    style: {
      maxHeight:"48vh",
      minHeight:"26em",
      minWidth:"13em",
      maxWidth:"15em"
    },
    headerFontSize: '1.125em'
  },
  mobileView: {
    maxCardTitleWithImage: 56,
    maxCardTitle: 200,
    style: {
      maxHeight:"50vh",
      minHeight:"24.8em",
      minWidth:"13em",
    },
    headerFontSize: '1.125em'
  },
  small: {
    maxCardTitleWithImage: 60,
    maxCardTitle: 120,
    style: {
        maxHeight:"30em",
        minWidth:"12em",
        maxWidth:"12em"
    },
    headerFontSize: '1em'
  }
};

export default function createCards( articleArray, cardSize="regular" ) {

  const cardStyle = cardConfig[cardSize]; 
  const { headerFontSize, maxCardTitleWithImage, maxCardTitle, style } = cardStyle;

  return articleArray.map( (article, index) => {
  const href = article.url_token ? `/a/${article.url_token}`
                                   : `/${article.url}`;
    let articleHeader;
    if (article.lead_image_url){ 
        articleHeader = (article.title.length > maxCardTitleWithImage) ? 
                        `${article.title.substring(0,maxCardTitleWithImage)}...`
                        : article.title;

    } else {
        articleHeader = (article.title.length > maxCardTitle) ? 
                        `${article.title.substring(0,maxCardTitle)}...`
                        : article.title;
    }
    const ignore = (<Icon link name='ellipsis vertical'></Icon>);
        return (<Card 
                  link
                  key={`${href}-${index}`}
                  style={style}
                >
                {article.lead_image_url ? 
                <Image 
                  style={{height: cardSize === 'mobileView' ? '15em': "10em"}}
                  src={article.lead_image_url} /> : null
                }
                <Card.Content
                  href={href}
                 >
                  <Card.Header textAlign="center" style={{fontSize: headerFontSize}}> 
                  {articleHeader}
                  </Card.Header>
                </Card.Content>
                  <Card.Meta>
                  <Segment basic style={{position: 'relative', minWidth:'1em'}}>
                       <span style={{position:'absolute', bottom:'.5em', left:'1em'}}>
                        {moment(article.date_published).fromNow()}
                        </span>
                        <span style={{position:'absolute', bottom:'.5em', right:'.5em'}}>
                        {dropdown(article)}
                </span>
             </Segment>
                  </Card.Meta>
                <Card.Content extra textAlign="left" >
                  <Card.Meta>
                  <div style={{minHeight:'1em', overflow:'hidden'}}>
                  {article.domain}
                  </div>
                  </Card.Meta>
                </Card.Content>
                </Card>);
  });
}
