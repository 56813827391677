import React, { Component } from 'react'; 
import { Redirect, Link } from 'react-router-dom';
import { Dropdown, Loader, Dimmer, Modal, Popup, TextArea, Input, Header, Grid, Label, Container, Image, Segment, Icon, Form, Button, Message} from 'semantic-ui-react';
import styled from 'styled-components';
import {withRouter} from 'react-router';
import { API } from '../config';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SignUp from './SignUp';
import ReactPlayer from 'react-player';
import uploadVideo from './utils/postVideo';
axios.defaults.withCredentials = false;
axios.defaults.crossDomain = true;
const Text = styled.span`
    h {
      font-family: Verdana, sans-serif; 
      font-size: 1.20em;
      line-height: 1.6;
    }
`;

const ASPECT_RATIO = 0.5625;
const isValidAspectRatio = ({videoHeight, videoWidth}) => {

  if(videoWidth/videoHeight !== ASPECT_RATIO)
    return false;

  return true;
};

const categories = [
    {key: 'vlog',
     text: 'Vlog',
     value: 'vlog',
     icon: {name: 'video', color:'pink'}
    },
    {key: 'entertain',
     text: 'Entertain',
     value: 'entertainment',
     icon: {name: 'star', color:'pink'}
    },
    {key: 'current',
     text: 'Current',
     value: 'current',
     icon: {name: 'lightning', color:'pink'}
    },
    {key: 'cool',
     text: 'Cool',
     value: 'cool',
     icon: {name: 'flask', color:'pink'}
    }
];

const VALID_MIMES = new Set(["video/mp4", "video/x-m4v", "video/mp4","video/quicktime","video/x-msvideo","video/mpeg", "video/webm"]);
class ProfileCard extends Component { 

  constructor (props) {
      super(props)
      this.state = {
          playVideo:false,
				  autoPlay:true,
          modalState: true,
          editMode: false,
          justFollowed: null,
          file: null,
          videoSrc: null,
					video: null,
          changed: false,
          category: 'vlog',
          errors: {},
          validated: false,
          success: undefined,
          errorCode: null, 
          submitted: false
      };

      this.validators = { 
        file: () => {
          if (!this.state.file) { return undefined; }
          const { type, size } = this.state.file;
          if (!VALID_MIMES.has(type)) {
            return "Valid file types are mp4, mov, mpg, webm, or avi";
          }

          if ( size >= (500 * 10**6) ) {
            return "The max file size is 300MB";
          }
          if(this.state.video) {
            if(!isValidAspectRatio(this.state.video)) {
              return "Video needs an aspect ratio of 9:16";
            }
          }
          return undefined;
        },
        description: () => {
          if (!this.state.description) { return undefined;}
          if (this.state.description.length > 112) {
            return "Your description has to be 112 characters or less"
          }
          return undefined;
        }
      };
  }


  fileChange = e => {
    URL.revokeObjectURL(this.state.videoSrc);
    const file = e.target.files[0];

    if(!file) {
		return
    }

    this.setState({ file, 
                    videoSrc: URL.createObjectURL(file),
                    changed: true,
                    errors: {},
                    success: undefined,
                    errorCode: null}, () => {
      console.log(file.type);
      console.log("File chosen --->", this.state.file);
    });
  };

  handleChange = (e, {name, value} ) => this.setState({[name]: value,
                                                       changed: true,
                                                       success: undefined,
                                                       validated: false,
                                                       errorCode: null,
													  });

  handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const { axiosClient } = this.props; 
    const { description, file } = this.state;
    const category = this.state.category;
    const metadata = {
        description,
        category
    };

    uploadVideo(axiosClient, file, metadata)
         .then( (resp) => { 
                    this.setState({success: true,
                                   imgSrc: null,
                                   file: null,
                                   thumbUploaded: file ? true : false,
                                   editMode: false,
                                   changed: false,
                                   loading: false});
                }).catch( (error) => {
                    const { response } = error;
                    this.setState({success: false,
                                   loading: false,
                                   errorCode: response});
                 });

      if(this.state.success === undefined)
        this.setState({loading: true});
  }

  getFieldError (field) {
    const { errors } = this.state;

    if (!errors[field])  
      return null;

    return { content: errors[field],
             pointing: "below"
            };

  }

  componentWillUnmount() {
     URL.revokeObjectURL(this.state.videoSrc);
  }

  componentDidMount() {
      const { aboutMe, firstName, lastName, profileToken, hasThumb } = this.props;
      this.setState({firstName, 
                     lastName, 
                     aboutMe});
  }

  componentWillReceiveProps(nextProps) {
    if(!this.state.firstName || !this.state.lastName) {
      const {firstName, lastName, aboutMe} = this.props;
      this.setState({firstName, lastName, aboutMe});
    }
  }

  componentDidUpdate() {
      console.log('biggaboom!', this.state.category);

      if(this.state.validated)
          return;

      if(this.state.success)
        return (<Redirect to='/profile'/>);

      const errors = {};
      const check_fields = {}
      Object.keys(this.validators)
            .filter( (key) => this.state[key] )
            .map( (key) => {
                const error = this.validators[key]();
                if( error ){
                    errors[key] = error;
                }
             });

      this.setState({errors: errors,
                     validated: true});
  }

  fileInputRef = React.createRef();
 
  fileUpload(e) {
  if(e) 
      e.preventDefault();
    this.fileInputRef.current.click();
  }; 

  setVideo = function() {
    // Store a 'this' ref, and
    const newVideo = document.querySelector('video');
	if(newVideo) {
     this.setState({video: newVideo, validated: false, errors:{}})
	}

   };


  createErrorMessage() {
    const { errorCode, errors } = this.state;
    if (!Object.keys(errors).length && !errorCode) {
      return null;
    }
  
  let errorMsg = "Seems like something went wrong.";
  if(errorCode === 409)
      errorMsg = "Oops weird"

  if (errors["file"]) {
    errorMsg = errors["file"]
  } else if (Object.keys(errors).length) {
    errorMsg = errors[ Object.keys(errors)[0] ]
  }

    return (<Message 
       negative 
       header="Oops, we can't upload your vlog" 
       content={errorMsg}
      />)
  }

  profileUploadText() { 
    let text="Upload your vlog";

    if (this.state.file) {
      const { name } = this.state.file;
      text = name < 20 ? name : `${name.substring(0,20)}...`
    }

    return this.state.file ? 
          (<span style={{fontWeight:'10'}}>{text}</span>) :
             text 
  }

  ref = player => {
    this.setState({player});
  };

  profileImagePreview() {
  const { videoSrc, playVideo, errors, autoPlay } = this.state;
  const { profileToken, hasThumb } = this.props;
  const previewCond = (videoSrc && !errors['file']);
  let videoUrl = '';

  if (previewCond) {
   videoUrl = videoSrc;
   return (
   <div style={{margin:'auto',display:'block', justifyContent:'center', alignItems:'center', height:'26.625em', width:'15em'}}>
    <ReactPlayer 
      url={videoUrl}
      onProgress={({loadedSeconds}) => {
          if(loadedSeconds > .5)
            this.setVideo();
        }
      }
      ref={this.ref}
      muted={!playVideo}
      height="26.625em"
      width="15em"
      onEnded={() => { 
        this.setState({playVideo:false}); 
      }}
      playing={playVideo}
    style={{margin:0, padding:0}}
      className='react-player'
      playsinline
      volume={1}
     />
     <div
         onClick={() => {
         this.setState({playVideo:!playVideo}); 
            }
            }
            style={{
               display:'flex',
               justifyContent:'center',
               position:'absolute', 
                top:'50%',
                left:'50%',
                height:'70%',
                width:'100%',
                zIndex:'15',
                transform:'translate(-50%,-50%)',
                }}
            >
          { !playVideo ? 
           <Icon 
             link size='large' color="white" inverted style={{maxHeight:'1.5em', maxWidth:'1.5em', margin:'auto', textShadow:'2px 2px 2px #E03977'}} name='play'
            /> : null
          }
      </div>
       <div 
        style={{
           padding:'1em',
           display:'block',
           width:'66%',
           position:'absolute', 
            left:'3em',
            bottom:'4.66em',
            zIndex:'20'
            }}
       >
       <Container text textAlign='left'>
       <Text>
       <Header as='h5' style={{color:'white'}}>
       {this.state.description} 
       </Header>
       </Text>
       </Container>
       </div>
    </div>
   )
  } 
    return (null);
  }

  render () {
      if(this.state.success) {
          setTimeout( () => {
          this.setState({redirect: true});
          }, 5000);
      }

      if(this.state.redirect) {
        return <Redirect to='/v/index' />
      };

      return (
            <Segment basic textAlign='center'>
            <Modal open={this.state.success}>
            <Modal.Header>
                Success!
            </Modal.Header>
            <Modal.Content>
              <p>Your vlog was uploaded and will be available in a few minutes.</p>
            </Modal.Content>
            <Modal.Content>
            <Segment basic padded="very">
            <Dimmer active inverted>
               <Loader inverted>Redirecting to vlogs by other users...</Loader>
            </Dimmer>
            </Segment>
            </Modal.Content>
            </Modal>
            <Form style={{margin:'auto'}}
              size='large' success={this.state.success} onSubmit={this.handleSave} loading={this.state.loading} warning={false}>
               {this.profileImagePreview.call(this)}
               <Form.Field style={{marginTop:'1em', marginBottom:'1em'}}>
                 <Button as='div' size='large' labelPosition='left' style={{marginTop:'5%'}}
                     onClick={this.fileUpload.bind(this)} >

                     <Label as='p' basic style={{borderRightStyle:'hidden', margin:'auto'}}>
                      {
                        this.profileUploadText()
                      }
                      </Label>
                     <Button size='large' basic style={{marginRight:'0'}}>
                       <Icon name='film' style={{margin:'0'}}color='pink'/>
                     </Button>
                  </Button>
                  <input ref={this.fileInputRef} hidden onChange={this.fileChange} name='video' type='file' accept="video/mp4;video/mp4;video/x-m4v;video/mp4;video/quicktime;video/x-msvideo;video/mpeg;video/webm" capture />
                </Form.Field>
            </Form>
            <Segment basic style={{marginBottom:'0', marginTop:'0'}}>
            <Form style={{maxWidth:'75%', margin:'auto'}} size='large' success={this.state.success} onSubmit={this.handleSave} loading={this.state.loading} warning={false}>
              <Form.Field error={this.getFieldError('category')}>
            {
              this.createErrorMessage()
            }
                 <label>Description</label>
                <TextArea placeholder="Write up to 112 characters describing your video..."
                       name='description'
                       onChange={this.handleChange}
                       value={this.state.description}
                       error={this.getFieldError('description')}  />

              </Form.Field> 
              <Form.Field style={{margin:'5%'}}>
               <span >
                Category:&nbsp; 
                <Dropdown
                  inline
                  onChange={ (e, { value } ) => this.setState({category: value}) }
                  options={categories}
                  defaultValue={categories[0].value}/>
                </span>
            </Form.Field>
          </Form>
           </Segment>
           <Button 
                   disabled={!this.state.changed || Object.keys(this.state.errors).length !== 0}
                   onClick={this.handleSave}
                   basic name="save" 
                   color="pink"
                   content="Submit"
           />
          </Segment>
      );
  }


}


export default ProfileCard;
