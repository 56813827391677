import React, { Component, createRef } from 'react';
import { Responsive, Divider, Comment, Image, Item, Visibility, Segment, Rail, Container, Header, Grid, Sticky, Ref} from 'semantic-ui-react';
import ArticleText from './ArticleText';
import ArticleHeader from './ArticleHeader'; 
import RecommendedView from './RecommendedView';
import RecommendedContent from './RecommendedContent';
import RecommendedHeader from './RecommendedHeader';
import CreateComment from './CreateComment';
import CommentView from './CommentView';
import {Helmet} from "react-helmet";
import sendRead from '../utils/sendRead';
import styled from 'styled-components';
import axios from 'axios';
const HorizontalScroll = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    
    .label {
      flex: 0 0 auto;
    }

`;
const FixScroll = styled.span`
    .fix {
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    flex-wrap: nowrap;
    display: flex;
    overflow-x: auto;
    flex: 0 0 auto;
        
    }
`;

const Text = styled.div`
    
    padding-bottom: 2em;
    p {
          font-family: Verdana, sans-serif; 
          display: block;
          margin-bottom: 1.24em;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          font-size: 1.20em;
          line-height: 1.6;
          color: #444443;
    }
    
    img { 
        max-width: 100%;
        max-height: auto;
    }   

`;

const SiteName = styled.span`
                 color: Grey;
                 padding-left: 0.5em;
                 font-style: bold;
                 font-size: small;
                 `;

const RecommendDiv = styled.div`
                
                        max-height: 30%;
                     `;


const SourceSection = styled.div`
                      padding-top: 2em;

                      img {
                          max-height: 15px;
                          max-width: 15px;
                          margin-bottom: .4em;

                      }

                      `;

class ArticleView extends Component {
    constructor (props) {
        super(props);
        this.contextRef = createRef();
        this.commentToPush = {};
        this.state = {
            json: {},
            updateNonce: null,
            error: false,
            readSent: null,
            height: window.innerHeight,
            calculations: {
              percentagePassed: 0
            }
        };
        this.updateWindowHeight = this.updateWindowHeight;
    }

    handleUpdate = (e, { calculations }) => this.setState({ calculations })

    commentHook = (comment) => {
        this.setState({updateNonce: Math.random()})
    };


    componentDidMount(){
        
        axios.get("https://intrgr-content.s3-us-west-1.amazonaws.com/" + 
                  this.props.articleKey).then( response => {
                    this.setState({json: response.data});
                  }).catch( err => {
                     this.setState({error: true}); 
                     console.log(err);
                  });

        window.addEventListener('resize', this.updateWindowHeight);
    }

    componentDidUpdate() { 
        const { percentagePassed } = this.state.calculations;
        const authToken = localStorage.getItem('authToken');
        const articleKey = this.props.articleKey;
        const submitToken = `submit-${articleKey}`;
        console.log(submitToken);

        if(!authToken || !this.props.AxiosClient || ( percentagePassed < .6  && !sessionStorage.getItem(submitToken) ) )
            return;

        const readSentKey = `readSent-${articleKey}`;

        if ( sessionStorage.getItem(readSentKey) )
            return;

        sendRead(this.props.AxiosClient, articleKey).then( () =>  {
            sessionStorage.setItem(readSentKey, "true");
            sessionStorage.removeItem(submitToken);
        });

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowHeight);

    }

    updateWindowHeight = () => {
        this.setState({height: window.innerHeight});

    }

    render() {
        const isMobile = this.props.width <= 500;
		const paragraph = <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        
        if(!this.state.error){
            const articleContainer = (
                    <Container text textAlign='justified' style={{width:'66%'}}>
                    <SourceHeader site_name={this.state.json.site_name}
                                         url={this.state.json.normal_url}/>

                    <ArticleHeader title={this.state.json.title} 
                                   date_published={this.state.json.date_published}
                                   author={this.state.json.author}
                                   url_token={this.state.json.url_token} />
                    {
                     this.state.json ? 
                    <ArticleText html={this.state.json.content} relUrl={this.state.json.lead_image_url || this.state.json.url} />
                     : null
                    }
                    </Container>
            );

              return  (
                    <>
                    <Responsive {...Responsive.onlyMobile}>
                    <Segment basic textAlign="center">
                    <Text>
                       {articleContainer}
                    </Text>
                      <Segment basic textAlign="left" style={{padding:'0', margin:'auto', maxWidth:'45em'}}>
					  <CreateComment 
                        commentHook={this.commentHook.bind(this)}
					    urlToken={this.state.json.url_token}
					    axiosClient={this.props.AxiosClient}/>
                      <CommentView
                        updateNonce={this.state.updateNonce}
                        articleToken={this.state.json.url_token}
                        axiosClient={this.props.AxiosClient}/>
                      <div style={{padding:'1em'}}/>
                      </Segment>
                      <RecommendedHeader attached="top" />
                      <FixScroll>
                      <Segment className="fix" basic style={{overflowY:'hidden', overflowX:'auto', flexDirection:'row'}}>
                        <div style={{padding:'.75em', display:'flex', overflowX:'auto', flexDirection: 'row', flexWrap: 'nowrap', alignItems:'center', overflowY:'hidden', }} className="fix">
                        <RecommendedView 
                                         size="small"
                                         cardsPerView={11}
                                         maxHeight={this.state.height}
                                         articleKey={this.props.articleKey}
                                         viewed_url={this.state.json.normal_url} />
                        </div>
                      </Segment>
                        </FixScroll>
                    </Segment>
                   </Responsive>
                   <Responsive {...Responsive.onlyComputer}>
                    <Helmet>
                      <meta property="og:url" content={`https://intrgr.com/a/${this.state.json.url_token}`} />
                      <meta property="og:type" content="article" />
                      <meta property="og:description" content={this.state.json.description} />
                      <meta property="og:image" content={this.state.json.lead_image_url} />
                      <meta property="og:title" content={this.state.json.title}/>
                    </Helmet>
                    <Grid centered>
                      <Grid.Row>
                      <Grid.Column>
                        <Container >
                        <Visibility onUpdate={this.handleUpdate}>  
                           <Text>
                          {articleContainer}
                          </Text>
                        </Visibility>
                          <div style={{margin:'auto', maxWidth:'45em'}}>
                          <CreateComment 
                            commentHook={this.commentHook.bind(this)}
                            urlToken={this.state.json.url_token}
                            axiosClient={this.props.AxiosClient}/>
                          <CommentView
                            updateNonce={this.state.updateNonce}
                            articleToken={this.state.json.url_token}
                            axiosClient={this.props.AxiosClient}/>
                          </div>

                        </Container>

                      </Grid.Column>
                      </Grid.Row>
                    
                      <Grid.Row>
                      <Grid.Column textAlign='center' verticalAlign='middle' width={12}>
                            <Divider style={{paddingBottom:'4%'}}/>
                            <RecommendedHeader attached="top" />
                            <RecommendedView 
                                             size="small"
                                             cardsPerView={4}
                                             maxHeight={this.state.height}
                                             articleKey={this.props.articleKey}
                                             viewed_url={this.state.json.normal_url} />
                      </Grid.Column>
                      </Grid.Row>
                    </Grid>
                   </Responsive>
                   </>
              )
            }

        return (<p>An Error has Occurred</p>);
    }

}

function guess_site_name(url){
    const first_delimiter_idx = url.indexOf("/");
    
    if (first_delimiter_idx > 8)
        return url.substring(0, first_delimiter_idx);

    var remove_until;
    if ( url[first_delimiter_idx + 1] === "/" )
        remove_until = 2;
    else
        remove_until = 1;

    const url_lstripped = url.substring( (first_delimiter_idx + remove_until),
                                         url.length);

    const end_delimiter = url_lstripped.indexOf("/");
    if (end_delimiter === -1)
        return url_lstripped;

    return url_lstripped.substring(0, end_delimiter);

}

class SourceHeader extends Component { 
    render () {
       
        let site_name, img_src;
        if (this.props.url) {
            site_name = !this.props.site_name ? guess_site_name(this.props.url)
                                              : this.props.site_name;
            site_name += " >";
            console.log(this.props.url);
        
            img_src = "https://www.google.com/s2/favicons?domain=" +
                      guess_site_name(this.props.url);
        }

        return (
            <SourceSection>
            <Header sub> 
            <img src={img_src} />
            <a href={this.props.url}>
            <SiteName>
            {site_name}
            </SiteName>
            </a>
            </Header>
            </SourceSection>
        );

    }

}

export default ArticleView;
