import React, { Component, createRef } from 'react';
import { Ref, Sticky, Icon, Responsive, Menu, Segment, Grid, Loader, Card, Container, Dimmer, Image } from 'semantic-ui-react';
import { Redirect, withRouter } from 'react-router-dom';
import getRecommended from '../utils/getRecommended';
import createCards from '../utils/createCards';
import createVideoCards from './utils/createVideoCards';
import Tutorial from './Tutorial';
import { ArticlePlaceholder } from './RecommendedContent';
import ArticleInput from './ArticleInput'; 
import CategoryMenu from './CategoryMenu';
import ProfileCard from './ProfileCard';
import ProfileCardMobile from './ProfileCardMobile';
import getCategory from './utils/getCategory';
import createProfileCards from './utils/createProfileCards';
import styled from 'styled-components';

const CARDS_PER_ROW = 3; 
const profileItems = ["comments", "videos", "followers", "following"];

const HorizontalScroll = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    
    .label {
      flex: 0 0 auto;
    }

`;

class ProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        recommended: [],
        profile: {},
        comments: [],
        videos: [],
        followers: [],
        following: [],
        attempted: false,
        activeItem: 'comments'
    }
  }

  componentDidMount() {
      const { key, isOwner, username, axiosClient } = this.props;
      let profileResource;
      let rawUrl = this.props.location.pathname;
      let useUsername = rawUrl.substring(3, rawUrl.length);
      const unauthedProfileResource = `profile/${useUsername}`

      if(isOwner && Object.keys(axiosClient.headers).length) {
        profileResource = 'profile'
      } else {
        console.log(this.props.location);
        profileResource = unauthedProfileResource;
      }

      this.props.axiosClient.get(profileResource).then( ({data}) => {
          console.log(data);
          if (!data) {
              return;
          }
          this.setState({key, profile: data});
      });

      profileItems.forEach( (item) => {
        this.retrieveProfileCards.call(this, item, profileResource);
      });
  }

  retrieveProfileCards (loadCardResource, profileResource) {
    let resource, cardStateName;
    switch (loadCardResource) {
      case("comments"):
        resource = "comment";
        cardStateName = "comments";
        break;
      case("videos"):
        resource = "video";
        cardStateName = "videos";
        break;
      case("following"):
        resource = "following";
        cardStateName = "following";
        break;
      case("followers"):
        resource = "follower";
        cardStateName = "followers";
        break;
      default:
        return;
    }
    this.props.axiosClient.get(`${profileResource}/${resource}`).then( ({data}) => {
         if(!data) {
           return;
         }
         console.log('found', data);
         this.setState({[cardStateName]: data[cardStateName]});
    });
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  createSpecificCard = function () {
    const { activeItem, profile } = this.state;
    console.log("hey", activeItem);
    const entityArray = this.state[activeItem];
    console.log(entityArray);
    if(activeItem === "comments" )
      return createCards(entityArray, "mobileView");
    if(activeItem === "videos")
      return createVideoCards(entityArray, profile.profileToken, profile.username);
    if(activeItem === "followers" || activeItem === "following") 
      return createProfileCards(entityArray, "mobileView");
  }
  contextRef = createRef();
  render() {
    const { attempted, recommended, activeItem, profile } = this.state;
    const { axiosClient } = this.props;


    let cards = (<span />);
    const placeHolderCards = !this.state[activeItem].length ? 1 : 0;
    cards = (<>
            <Responsive {...Responsive.onlyComputer}>
            <Card.Group stackable itemsPerRow={CARDS_PER_ROW} style={{paddingBottom:"3em"}}>
            {this.createSpecificCard.call(this)}
            {[...Array(placeHolderCards).keys()].map( iter => {
               if (iter === 0) {
                 return(<Card
                       key="intrgr_card"
                       as="a"
                       style={{minHeight:"30vh",
                               maxHeight:"30vh",
                               maxWidth:"15em"}}
                       >
                       <Card.Content>
                          <Card.Header>
                   {`This profile has no ${activeItem} yet.`}
                          </Card.Header>
                       </Card.Content>
                       <Card.Content extra textAlign="left">
                       intrgr.com
                       </Card.Content>
                       </Card>
                       );
               }
                return ( ArticlePlaceholder(iter) );
              })
            }
            </Card.Group>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
            <Card.Group stackable itemsPerRow={1}>
            {this.createSpecificCard.call(this)}
            {[...Array(placeHolderCards).keys()].map( iter => {
               if (iter === 0) {
                 return(<Card
                       key="intrgr_card"
                       as="a"
                       style={{minHeight:"30vh",
                               maxHeight:"70vh",
                               }}
                       >
                       <Card.Content>
                          <Card.Header>
                   {`This profile has no ${activeItem} yet.`}
                          </Card.Header>
                       </Card.Content>
                       <Card.Content extra textAlign="left">
                       intrgr.com
                       </Card.Content>
                       </Card>
                       );
               }
                return ( ArticlePlaceholder(iter) );
              })
            }
            </Card.Group>
            </Responsive>
            </>
    );

    return (  <> 
              <Responsive {...Responsive.onlyMobile}> 
              <Container>
              <Ref innerRef={this.contextRef}>
              <Grid>
              <Grid.Row>
              <Grid.Column verticalAlign="middle">
                 <ProfileCardMobile username={profile.username}
                              hasThumb={profile.hasThumb}
                              aboutMe={profile.aboutMe}
                              isFollowed={profile.isFollowed}
                              isOwner={this.props.isOwner}
                              axiosClient={axiosClient}
                              profileToken={profile.profileToken}
                   />
              <Sticky context={this.contextRef}>
              <Segment basic style={{marginTop:'0', backgroundColor:'white'}}>
              <HorizontalScroll>
              <Menu pointing secondary size='large'>
                 <Menu.Item
                   name='videos'
                   active={activeItem === 'videos'}
                   onClick={this.handleItemClick}
                 />
                 <Menu.Item
                   name='comments'
                   active={activeItem === 'comments'}
                   onClick={this.handleItemClick}
                 />
                 <Menu.Item
                   name='following'
                   active={activeItem === 'following'}
                   onClick={this.handleItemClick}
                 />
                 <Menu.Item
                   name='followers'
                   active={activeItem === 'followers'}
                   onClick={this.handleItemClick}
                 />
               </Menu> 
                </HorizontalScroll>
              </Segment>
                </Sticky>
               </Grid.Column>
               </Grid.Row>
                <Grid.Row>
                <Grid.Column>
                <Segment basic>
                {cards}
                </Segment>
               </Grid.Column>
               </Grid.Row>
               </Grid>
                </Ref>
                </Container>
               </Responsive>
              <Responsive {...Responsive.onlyComputer}> 
              <Grid container centered columns={1} style={{minWidth:'52vw'}}>
              <Grid.Column verticalAlign="middle" style={{maxWidth:'52vw', minWidth:'50em'}}>
                <Segment.Group>
                 <ProfileCard firstName={profile.firstName}
                              lastName={profile.lastName}
                              hasThumb={profile.hasThumb}
                              aboutMe={profile.aboutMe}
                              isFollowed={profile.isFollowed}
                              isOwner={this.props.isOwner}
                              axiosClient={axiosClient}
                              profileToken={profile.profileToken}
                   />
              <Segment basic>
              <Menu pointing secondary size='large'>
                 <Menu.Item
                   name='comments'
                   active={activeItem === 'comments'}
                   onClick={this.handleItemClick}
                 />
                 <Menu.Item
                   name='videos'
                   active={activeItem === 'videos'}
                   onClick={this.handleItemClick}
                 />
                 <Menu.Item
                   name='following'
                   active={activeItem === 'following'}
                   onClick={this.handleItemClick}
                 />
                 <Menu.Item
                   name='followers'
                   active={activeItem === 'followers'}
                   onClick={this.handleItemClick}
                 />
               </Menu> 
              </Segment>
            {/*
                { this.state.activeItem === 'profile' ? <ProfileEdit axiosClient={axiosClient} profile={profile}/> :                
                  {cards}
                }
                */}
                <Segment padded basic>
                {cards}
                </Segment>
                </Segment.Group>
               </Grid.Column>
               </Grid>
               </Responsive>
               </>
            )  
    
  }
}

export default withRouter(ProfileView);
