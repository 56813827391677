import React, { Component } from 'react';
import ArticleView from './components/ArticleView';
import { AxiosClient, getAxiosClient } from './utils/AxiosClient';
import storeSession from './utils/storeSession';
import { Container, Responsive } from 'semantic-ui-react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import Common from './components/Common';
import HomeView from './components/HomeView';
import MobileView from './components/MobileView';
import CategoryMenu from './components/CategoryMenu';

class Home extends Component {

    render() {
        const { AxiosClient } = this.props;
        return (
                <Container>
                <Responsive {...Responsive.onlyComputer}> 
                 <HomeView AxiosClient={AxiosClient}/>
                </Responsive>
                <Responsive {...Responsive.onlyMobile}> 
                  <MobileView AxiosClient={AxiosClient}/>
                </Responsive>
                </Container>);
    }

}

export default Home;
