import React, {Component} from 'react';
import {withRouter} from 'react-router';

import { Icon, Input, Button, Form, Message, Header, Segment, Container } from 'semantic-ui-react';

class ArticleInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      link: null,
    };
  }

  handleChange = (e, {value} ) => {
      this.setState({link: value}); 
  }

  handleSubmit = () => {
    this.props.history.push(`/${this.state.link}`);
  }

  render() {
    return    (<Form onSubmit={this.handleSubmit}>
              <Input fluid 
                  icon
                  size='small'
                  placeholder='Share an article link to see what others have to say'
                  name='link'
                  onChange={this.handleChange}
              >
              <input style={{borderRadius: '100px'}} />
              {this.state.link !== null ? 
              <Icon name='send' link onClick={this.handleSubmit}/> :
              <Icon name='send' link />
              }

        </Input>
        </Form>
    );
  }

}

export default ArticleInput = withRouter(ArticleInput);
