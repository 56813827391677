import React, { Component } from 'react'; 
import { Redirect, Link } from 'react-router-dom';
import { Responsive, Modal, Popup, TextArea, Input, Header, Grid, Label, Container, Image, Segment, Icon, Form, Button, Message} from 'semantic-ui-react';
import styled from 'styled-components';
import {withRouter} from 'react-router';
import { API } from '../config';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SignUp from './SignUp';
axios.defaults.withCredentials = false;
axios.defaults.crossDomain = true;

const VALID_MIMES = new Set(["image/png", "image/jpg", "image/jpeg"]);
class ProfileCardMobile extends Component { 

  constructor (props) {
      super(props)
      const { username, aboutMe, profileToken } = this.props;
      this.state = {
          username,
          profileToken,
          modalState: true,
          justFollowed: null,
          file: null,
          imgSrc: null,
          changed: false,
          errors: {},
          validated: false,
          success: undefined,
          errorCode: null, 
          submitted: false
      };

      this.validators = { 
        username: () =>  { 
             const regex = /[^a-zA-Z0-9]/;
             return (!this.state.username || 
                     this.state.username.match(regex) )  ? 
                     "Create a username." :
                     undefined;
        },
        file: () => {
          if (!this.state.file) { return undefined; }
          const { type, size } = this.state.file;
          if (!VALID_MIMES.has(type)) {
            return "Valid file types are jpeg, jpg, or png";
          }

          if ( size >= (3.2 * 10**6) ) {
            return "The max file size is 3MB";
          }
          return undefined;
        },
        aboutMe: () => {
          if (!this.state.aboutMe) { return undefined;}
          if (this.state.aboutMe.length > 240) {
            return "Your About Me section has to be less than 240 characters"
          }
          return undefined;
        }
      };
  }


  fileChange = e => {
  if (this.state.imgSrc) 
    URL.revokeObjectURL(this.state.imgSrc);
  const file = e.target.files[0];
    this.setState({ file, 
          imgSrc: URL.createObjectURL(file),
                    changed: true,
                    success: undefined,
          validated: false, 
          errorCode: null}, () => {
    
      console.log("File chosen --->", this.state.file);
    });
  };

  handleChange = (e, {name, value} ) => this.setState({[name]: value,
                                                       changed: true,
                                                       success: undefined,
                                                       validated: false,
                                                       errorCode: null});

  handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const { axiosClient } = this.props; 
    const { aboutMe, username, file } = this.state;
    if (username)
      formData.set('username', username);
    if (aboutMe)
      formData.set('aboutMe', aboutMe);

    if (file) 
        formData.set('file', file);

    axiosClient.formUpload('profile', formData)
         .then( (resp) => { 
                    this.setState({success: true,
                                   imgSrc: null,
                                   file: null,
                                   thumbUploaded: file ? true : false,
                                   changed: false,
                                   loading: false});
                    window.location.reload();
                }).catch( (error) => {
                    const { response } = error;
                    this.setState({success: false,
                                   loading: false,
                                   errorCode: response});
                 });

      if(this.state.success === undefined)
        this.setState({loading: true});
  }

  getFieldError (field) {
    const { errors } = this.state;

    if (!errors[field])  
      return null;

    return { content: errors[field],
             pointing: "below"
            };

  }

  componentWillUnmount() {
     URL.revokeObjectURL(this.state.imgSrc);
  }

  componentDidMount() {

      const { aboutMe, username, profileToken, hasThumb } = this.props;
      this.setState({username, 
                       aboutMe});
  }

  componentWillReceiveProps(nextProps) {
    if(!this.state.username) {
      const { username, aboutMe } = this.props;
      this.setState({username, aboutMe});
    }
  }

  componentDidUpdate() {
      if(this.state.validated)
          return;

      if(this.state.success)
        return (<Redirect to='/profile'/>);

      const errors = {};
      const check_fields = {}
      Object.keys(this.validators)
            .filter( (key) => this.state[key] )
            .map( (key) => {
                const error = this.validators[key]();
                if( error ){
                    errors[key] = error;
                }
             });

      this.setState({errors: errors,
                     validated: true});
  }

  fileInputRef = React.createRef();
 
  fileUpload(e) {
  if(e) 
      e.preventDefault();
    this.fileInputRef.current.click();
  }; 


  createErrorMessage() {
    const { errorCode, errors } = this.state;
    if (!Object.keys(errors).length && !errorCode) {
      return null;
    }
  
  let errorMsg = "Seems like something went wrong.";
  if(errorCode === 409)
      errorMsg = "Oops weird"

  if (errors["file"]) {
    errorMsg = errors["file"]
  } else if (Object.keys(errors).length) {
    errorMsg = errors[ Object.keys(errors)[0] ]
  }

    return (<Message 
       negative 
       header="Oops, we can't update your profile" 
       content={errorMsg}
      />)
  }

  profileUploadText() { 
    let text="Upload a profile picture";

    if (this.state.file) {
      const { name } = this.state.file;
      text = name < 20 ? name : `${name.substring(0,20)}...`
    }

    return this.state.file ? 
          (<span style={{fontWeight:'10'}}>{text}</span>) :
             text 
  }

  profileImagePreview() {
  const { imgSrc, errors } = this.state;
  const { profileToken, hasThumb } = this.props;
  const previewCond = (imgSrc && !errors['file']);
  let imageUrl = '../../../assets/user_placeholder.png';

  if (previewCond) {
    imageUrl = imgSrc;
      return (<Image src={imageUrl} 
              style={{maxWidth:'8em', minWidth:'5em', margin:'auto'}}
              size='tiny' />);
  } 

    if (hasThumb) 
       imageUrl = `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/thumbnail.png`;
    return (<Image src={imageUrl} size='small' style={{maxWidth:'8em', minWidth:'5em', margin:'auto'}}circular />);
  }

  handleOpen() {
    this.setState({isOpen:true});
    const timeoutLength = 230;
    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false })
    }, timeoutLength)
 
  }

  handleFollow(action) {
    const { axiosClient, profileToken } = this.props; 
    switch(action) {
    case("follow"):
      axiosClient.post(`profile/${profileToken}`, {action:'follow'}).then( (data) => {
        this.setState({justFollowed: true});
      });
      break;
    case("unfollow"):
      axiosClient.post(`profile/${profileToken}`, {action:'unfollow'}).then( (data) => {
        this.setState({justFollowed: false});
      });
      break;
    default:
      break;
    }
  }

  handleClose () { 
        const { username, aboutMe } = this.props;
        const { success } = this.state;
        this.setState({
          username: success && this.state.username ? this.state.username : username,
          aboutMe: success && this.state.aboutMe ? this.state.aboutMe : aboutMe
        });
  };

  render () {
    const { justFollowed, thumbUploaded } = this.state;
    const { isuser, isFollowed, profileToken, username, isOwner, aboutMe, hasThumb } = this.props;
    const imageUrl = (hasThumb || thumbUploaded) ? `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/thumbnail.png`
                                                 : '../../../assets/user_placeholder.png';
    return (
         <Segment.Group style={{boxShadow:'none', borderStyle:'none', borderBottomStyle:'none', marginBottom:'0'}}>
         <Segment.Group horizontal style={{boxShadow:'none', borderStyle:'none', borderBottomStyle:'none'}}>
         <Segment padded basic >
        {
          <Image src={imageUrl} size='small' style={{maxWidth:'8em', 
                                                     minWidth:'5em', 
                                                     marginRight:'0'}} circular />
        }
         </Segment>
        <Segment style={{borderStyle:'none'}}>
         {
         !localStorage.getItem("authToken") ? 
          (<Modal
            size="mini"
            trigger={<Button floated='right'
                      basic name="Follow"
                      content="Follow"
                      color="pink"
                      />}
           >
           <Modal.Header style={{textAlign:'center'}}>
            Sign Up or {<Link to="/login">Login</Link>} to follow your favorite people
           </Modal.Header>
           <Modal.Content style={{textAlign:'center', padding:'10%'}}> 
            <SignUp style={{margin:'auto'}}/>
           </Modal.Content>
           </Modal>) : (isOwner ? (
             <Modal
              size='mini'
              onClose={this.handleClose.bind(this)}
              centered={false}
              trigger={<Button floated='right'
                               basic name='Edit'
                               content="Edit"
                               color='pink'
                               />}
             >
           <Modal.Header style={{textAlign:'center'}}>
            Edit your Profile
           </Modal.Header>
           <Modal.Content style={{textAlign:'center', padding:'10%'}}> 
            <Form style={{margin:'auto'}}
              size='large' success={this.state.success} onSubmit={this.handleSave} loading={this.state.loading} warning={false}>
               {this.profileImagePreview.call(this)}
               <Form.Field>
                 <Button as='div' size='mini' labelPosition='left' style={{marginTop:'5%'}}
                     onClick={this.fileUpload.bind(this)} >

                     <Label as='p' basic style={{borderRightStyle:'hidden', margin:'auto'}}>
                      {
                        this.profileUploadText()
                      }
                      </Label>
                     <Button size='mini' basic style={{marginRight:'0'}}>
                       <Icon name='camera' style={{margin:'0'}}color='pink'/>
                     </Button>
                  </Button>
                  <input ref={this.fileInputRef} hidden onChange={this.fileChange} name='profilePic' type='file' />
                </Form.Field>
            </Form>
            <Segment basic style={{marginBottom:'0', marginTop:'0'}}>
            <Form style={{maxWidth:'75%', margin:'auto'}} size='mini' success={this.state.success} onSubmit={this.handleSave} loading={this.state.loading} warning={false}>
            <Form.Field error={this.getFieldError('username')}>
              <label>Username</label>
              <Input placeholder='Create a username.'
                          name='username'
                          onChange={this.handleChange}
                          type='username'
                          value={this.state.username}
                          error={this.getFieldError('username')}
                          />
            </Form.Field> 
              <Form.Field error={this.getFieldError('aboutMe')}>
                 <label>About Me</label>
                <TextArea placeholder="Write up to 240 characters about yourself..."
                       name='aboutMe'
                       onChange={this.handleChange}
                       value={this.state.aboutMe}
                       error={this.getFieldError('aboutMe')}  />

              </Form.Field> 
          </Form>
           </Segment>
            {
              this.createErrorMessage()
            }
           <Button 
                   disabled={!this.state.changed || Object.keys(this.state.errors).length !== 0}
                   onClick={this.handleSave}
                   basic name="save" 
                   color="pink"
                   content="Save"
           />
           </Modal.Content>
           </Modal>) : (
             <Button floated='right' 
                 basic name="Follow" 
                 color="pink"
                 onClick={(((isFollowed && justFollowed !== false) || justFollowed) ? () => this.handleFollow.call(this, "unfollow") : () => this.handleFollow.call(this, "follow") )
                 }
                 content={((isFollowed && justFollowed !== false) || justFollowed) ? "Unfollow" : "Follow"
                 } 
         />)
           )
       }
          </Segment>
          </Segment.Group>
         <Segment basic style={{paddingTop:'0'}}>
          <Header as='h2' textAlign='left' style={{marginTop:'0'}}>
            {`${this.state.username && this.state.success ? this.state.username : (username ? username : 'Username')}`}
            <Popup
                content="Copied!"
                on='click'
                trigger={
                    (<CopyToClipboard text={`https://intrgr.com/p/${this.props.profileToken}`}>
                        <Icon 
                        style={{fontSize:'.6em', marginLeft:'.15em', marginBottom:'.66em'}} 
                        size='tiny' 
                        onClick={this.handleOpen.bind(this)} 
                        link 
                        name='copy outline' 
                        color='pink' />
                    </CopyToClipboard>)
                }
                open={this.state.isOpen}
                onOpen={this.handleOpen}
                position='top center'
            />
          </Header>
          <Header as='h4' textAlign='left' style={{marginTop:'0', fontWeight:'normal'}}>
            { aboutMe ? aboutMe : 
              "This user doesn't have a bio yet."
            }
          </Header>
         </Segment> 
          </Segment.Group>
      );
    }
}


export default ProfileCardMobile;
