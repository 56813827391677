import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Loader, Segment} from 'semantic-ui-react'
import styled from 'styled-components';
import ArticleView from './components/ArticleView';
import {API} from './config';
import axios from 'axios';
import { getAxiosClient } from './utils/AxiosClient';

const Center = styled.div`
               margin: auto;
               padding: 15%;
               `;

class Transfer extends Component {
    constructor (props) {
        super(props)
        this.state = { 
            article_token: null,
        }
    }
    componentDidMount() {
        let raw_url = this.props.location.pathname;
        let parsed_url = raw_url.substring(1, raw_url.length);
        let base64_url = new Buffer(parsed_url).toString('base64');
        const axiosClient = getAxiosClient();

        axiosClient.get(`content/${base64_url}`)
             .then( ({data}) => {
                 console.log(data.token);
                this.setState({article_token: data.token});
             })
             .catch( err => {
                this.setState({article_token: ""});
             });
    }

    render() {

        if(this.state.article_token === null)
            return (
                   <Center>
                   <Loader active 
                           inline='centered' 
                           content='Cleaning this article just for you' />
                   </Center>
                   );

        if(!this.state.article_token)
            return(<p>An error occurred.</p>);

        if(this.state.article_token)
            sessionStorage.setItem(`submit-${this.state.article_token}`, 'true');

        return <Redirect to={`/a/${this.state.article_token}`} />;
    }

}

export default Transfer;
