import React, { Component, createRef } from 'react';
import { Responsive, Divider, Comment, Image, Item, Visibility, Segment, Rail, Container, Header, Grid, Sticky, Ref} from 'semantic-ui-react';
import ArticleText from './ArticleText';
import ArticleHeader from './ArticleHeader'; 
import RecommendedView from './RecommendedView';
import RecommendedContent from './RecommendedContent';
import RecommendedHeader from './RecommendedHeader';
import CreateComment from './CreateComment';
import CommentView from './CommentView';
import {Helmet} from "react-helmet";
import sendRead from '../utils/sendRead';
import styled from 'styled-components';
import axios from 'axios';

class VideoCommentView extends Component {
    constructor (props) {
        super(props);
        this.contextRef = createRef();
        this.commentToPush = {};
        this.state = {
            json: {},
            updateNonce: null,
            error: false,
        };

    }

    handleUpdate = (e, { calculations }) => this.setState({ calculations })

    commentHook = (comment) => {
        this.setState({updateNonce: Math.random()})
    };

    componentDidUpdate() { 
        const authToken = localStorage.getItem('authToken');
    }

    render() {
        
              return  (
                    <>
                    <Responsive {...Responsive.onlyMobile}>
                      <Container>
                      <Segment basic textAlign="left" style={{padding:'0', margin:'auto'}}>
					  <CreateComment 
                        commentHook={this.commentHook.bind(this)}
					    urlToken={this.props.videoToken}
					    axiosClient={this.props.axiosClient}/>
                      <CommentView
                        updateNonce={this.state.updateNonce}
                        videoToken={this.props.videoToken}
                        axiosClient={this.props.axiosClient}/>
                    </Segment>
                    </Container>
                   </Responsive>
                   </>
              );
            }
}

export default VideoCommentView;
