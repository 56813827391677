import axios from 'axios';
import { API } from '../config';
export default function getVideo (resource="all", offset=0) {
   return axios.get(`${API}/video/category/${resource}?offset=${offset}`).then( (response) => {
       if(response.status === 200){
           console.log(response.data.videoMetadata);
           return response.data.videoMetadata; 
       }

       return [];

   });
}
