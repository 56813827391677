import React, { Component } from 'react';
import { Responsive, List, Header, Menu, Segment, Grid, Loader, Card, Container, Dimmer, Image } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import getRecommended from '../utils/getRecommended';
import createCards from '../utils/createCards';
import Tutorial from './Tutorial';
import SignUp from './SignUp';
import { ArticlePlaceholder } from './RecommendedContent';
import ArticleInput from './ArticleInput';
import CategoryMenu from './CategoryMenu';
import AuthInvite from './AuthInvite';
import getCategory from './utils/getCategory';

const CARDS_PER_ROW = 4;

class HomeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        recommended: [],
        loading: false,
        attempted: false,
        authed: localStorage.getItem("authToken")
    }
    if (!this.state.authed && localStorage.getItem("feedCategoryId") === null)
        localStorage.setItem("feedCategoryId", 1);
  }

  populateHomeView (categoryId, dateRange='today') {
      localStorage.setItem('feedCategoryId', categoryId);
      if (categoryId === 0) {

        if (!this.state.authed) {
          this.setState({recommended:[]});
          return;
        }

        getRecommended(this.props.AxiosClient).then( candidates => 
                                          this.setState({recommended: candidates,
                                                         attempted: true}) 
                                        );
      } else {
        this.setState({loading: true});
        getCategory(this.props.AxiosClient, categoryId, dateRange).then( candidates => {
            this.setState({recommended: candidates, loading: false});
        });
      }
  };

  componentDidMount() {
      const { AxiosClient } = this.props;
      const { authed } = this.state;

      const savedCategoryId = parseInt( localStorage.getItem("feedCategoryId") );


      if (savedCategoryId && savedCategoryId !== 0) {
        this.setState({loading:true});
        getCategory(this.props.AxiosClient, savedCategoryId, 'today').then( candidates => {
            this.setState({recommended: candidates,
                           loading:false,
                           attempted: true});
        });
      } else if (authed) {
        getRecommended(AxiosClient).then( candidates => 
                                          this.setState({recommended: candidates,
                                                         attempted: true}) 
                                        );
      }
  }

  render() {
    const { attempted, recommended, authed } = this.state;
      const savedCategoryId = parseInt( localStorage.getItem("feedCategoryId") );

    if(authed && !attempted) {
      return (<Container text>
                <Dimmer active inverted>
                    <Loader active size='medium'>
                       Making your home comfy
                    </Loader>
                </Dimmer>
                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'/>
                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'/>
                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'/>
                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'/>
                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'/>
                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'/>
                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'/>
          </Container>);
    }
    let cards = (<span />);

      const placeHolderCards = this.state.loading ? 0 : (!savedCategoryId && !authed) ? 12 : (recommended.length < 12 ? (CARDS_PER_ROW - recommended.length % CARDS_PER_ROW) : 0);

      cards = (
              <Card.Group centered stackable itemsPerRow={CARDS_PER_ROW} style={{paddingBottom:"3em"}}>
              {createCards(recommended)}
              {[...Array(placeHolderCards).keys()].map( iter => {
                 if (iter === 0) {
                   return(<Card
                         href="/setup"
                         key="intrgr_card"
                         style={{maxHeight:"70vh",
                                 maxWidth:"15em"}}
                         >
                         <Card.Content>
                            <Card.Header>
                              We're searching the web right now.
                            </Card.Header>
                            <Card.Description>
                             The web's a big place. As we find more articles we'll populate them here.
                            </Card.Description>
                         </Card.Content>
                         <Card.Content extra textAlign="left">
                         intrgr.com
                         </Card.Content>
                         </Card>
                         );
                 }
                  return ( ArticlePlaceholder(iter) );
                })
              }
              </Card.Group>
              
             );
    return (
            <Grid centered  
                  container
                  style={{height: '50vh'}}
                  verticalAlign='middle'>
            <Grid.Row centered columns={1}>
            <Grid.Column style={{maxWidth: "65vw", minWidth:"50em"}}>
              <CategoryMenu populateHomeView={this.populateHomeView.bind(this)} />
              <Loader size='large' active={this.state.loading} style={{position: 'absolute', top:'15vw'}} />
           {savedCategoryId || authed ? (!savedCategoryId && !recommended.length ? <Tutorial /> : cards)  : 
            (<Dimmer.Dimmable as={Segment} blurring dimmed={true}>
            <Dimmer active={true}
                    verticalAlign='top'
                    style={{padding:'5%'}}
            >
            <Segment.Group horizontal>
            <Segment padded textAlign="left" style={{maxWidth:'55%'}}>
              <Header textAlign="center">
                Sign up for your personalized Home
              </Header>
              <List size="large">
               <List.Item>
                <List.Icon name='user' color='pink'/>
                <List.Content>
                <List.Header>
                  Always in the know
                </List.Header>
                <List.Description>
                Be kept up to date with the most relevant articles for you from around the web, right here
                </List.Description>
               </List.Content>
                </List.Item>
                <List.Item>
                 <List.Icon name='globe' color='pink' />
                 <List.Content>
                  <List.Header>
                    Share
                  </List.Header>
                  <List.Description>
                    Discover or post your own articles to discuss with others who have the same interests
                  </List.Description>
                </List.Content>
                </List.Item>
                <List.Item>
                 <List.Icon name='magic' color='pink' />
                 <List.Content>
                  <List.Header>
                    Dive Deep
                  </List.Header>
                  <List.Description>
                    Find all the related articles to any you discover or submit
                  </List.Description>
                </List.Content>
                </List.Item>
              </List>
            </Segment>
            <Segment padded> 
            <SignUp style={{margin:'auto'}}/>
            </Segment>
            </Segment.Group>
            </Dimmer>
            {
             cards
            }
            </Dimmer.Dimmable>)
            }
            </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={1}>
            <Grid.Column style={{maxWidth: "60vw"}}> 
            </Grid.Column>
            </Grid.Row>
            </Grid>
            )  
    
  }
}

export default HomeView;
