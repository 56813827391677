
export default function sendRead (AxiosClient, articleKey) {
   return AxiosClient.post("content", 
   {"url_token": articleKey} 
   ).then( (response) => {

       if(response.data.status === 'success')
            return response.data.url_token; 

       return false; 
   }).catch( () => false );
}
