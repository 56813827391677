import React, { Component } from 'react'
import { Menu, Segment, Icon } from 'semantic-ui-react'


export default class DateMenu extends Component {
      state = {
          dateRange: 'today'
      };

      handleItemClick = (e, { name }) => { 
          this.setState({dateRange: name}, () => {
            this.props.populateHomeView(this.state.dateRange);
          });
      };

      render() {
              const { dateRange } = this.state;
              return (
                          <Menu text compact>
                            <Menu.Item
                              name='today'
                              active={dateRange === 'today'}
                              onClick={this.handleItemClick}
                            />
                            <Menu.Item
                              name='week'
                              active={dateRange === 'week'}
                              onClick={this.handleItemClick}
                            />
                            <Menu.Item
                              name='month'
                              active={dateRange === 'month'}
                              onClick={this.handleItemClick}
                            />
                            <Menu.Item style={{paddingLeft:'0em'}}
                              name='all'
                              active={dateRange === 'all'}
                              onClick={this.handleItemClick}
                            />
                          </Menu>
              );
      }
}
