import axios from 'axios';
axios.defaults.crossDomain = true;

export default function storeSession (authKey) {
   return axios.post("https://api.intrgr.com/user/auth", {
       authcode: authKey
   }).then( (response) => {
       localStorage.setItem("authToken", response.data.accessToken);
       return response.data.accessToken;
   }).catch( () => false );

}
