import React, { Component, useState, useEffect } from 'react'; 
import { Redirect, Link } from 'react-router-dom';
import { Button, Form, Message, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import {withRouter} from 'react-router';
import { API } from '../config';
import axios from 'axios';

export default function CreateComment (props) { 
  const isAuthed = !!localStorage.getItem("authToken");
  const { commentHook } = props;

  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({});
  const [authMsg, setAuthMsg] = useState(false);
  const [success, setSuccess] = useState();
  const [failureCode, setFailureCode] = useState();
  const [validated, setValidated] = useState(true);
  const [axiosClient, setAxiosClient] = useState(props.axiosClient);

  const inputs = [comment];
  const mapInput= {"comment": setComment};

  const handleChange = (e, {name, value} ) => { 
    console.log(name);
    console.log(value);
    mapInput[name](value);
    console.log(`state changed ${value}`);
    //setSuccess(undefined);
    //sesetValidated(false);

  }

  const validators = { 
    comment: () => {
      if (!comment) { return undefined;}
      if (comment.length > 1000) {
        return "Your comment has to be less than 1000 characters";
      }
      return undefined;
    },
    badResponse: () => {
      if (failureCode == 422) {
        return 422;
      }
      return undefined;
    }
  };

  const getFieldError = function (field) {

    if (!errors[field])  
      return null;

    return { content: errors[field],
             pointing: "below"
            };

  };

  useEffect( () => {

	  if (props.axiosClient && !axiosClient)
      	setAxiosClient(props.axiosClient)

      const errors = {};
      Object.keys(validators)
            .forEach( (key) => {
                const error = validators[key]();
                if( error ){
                    errors[key] = error;
                }
             });

      setErrors(errors);
  }, [comment, validated, props.axiosClient, failureCode]);



  const createErrorMessage = function() {

      if (authMsg) {
          return (<Message floating info>
              {<a style={{color:"#E03997"}} href='/signup'>Sign Up</a>} or {<a style={{color:"#E03997"}} href='/login'>Login</a>} to post a comment 
                  </Message>)
      }
    if (!Object.keys(errors).length) {
      return null;
    }

    if (errors["badResponse"] == 422) {
          return (<Message floating info>
              {<Link to="/profile" style={{color:"#E03997"}}>Click here</Link>} to setup your profile to post comments.
                  </Message>)
    }

   
    let errorMsg = "Seems like something went wrong.";

    errorMsg = errors[ Object.keys(errors)[0] ]

    return (<Message negative>
    <Message.Header> 
        Your comment couldn't be posted 
    </Message.Header>
    {errorMsg}
    </Message>)

  }

  const handleSubmit = function () {

    if (!isAuthed) {
        setAuthMsg(true);
        return;
    }

    axiosClient.post(`video/${props.urlToken}/comment`, {
		comment
	}).then(resp => {
        if ( !localStorage.getItem("authToken") ) {
            setAuthMsg(true); 
            return;
        }
		setSuccess(true);
        commentHook(comment);
        setComment("");
	}).catch(err => {
        setFailureCode(err.response.status);
    });
  };

  console.log(errors);

  return(
  <Form style={{marginBottom:'4em',
  			    marginTop:'2em'}}>
            { createErrorMessage() }
           <Form.Field error={errors['comment']}>
            <label>Comment</label>
            <TextArea placeholder="Add your comment here..."
                 name='comment'
                 onChange={handleChange}
                 value={comment} />
            </Form.Field> 
            <Form.Button 
				onClick={handleSubmit}
                disabled={errors["comment"]}
				basic color='pink' compact floated='right'>
                Add Comment
            </Form.Button>
          </Form>
  );

};
