import React, {Component} from 'react';
import {withRouter} from 'react-router';

import { List, Responsive, Button, Form, Message, Header, Segment, Container } from 'semantic-ui-react';
import styled from 'styled-components';

const Text = styled.span`
  font-family: Verdana, sans-serif;
  color: #444443;
`;

class Tutorial extends Component {

  constructor(props) {
    super(props);
    this.state = {
      link: null,
    };
  }

  handleChange = (e, {value} ) => {
      this.setState({link: value}); 
  }

  handleSubmit = () => {
    this.props.history.push(`/${this.state.link}`);
  }

  render() {
    return (<>
           <Responsive {...Responsive.onlyMobile}>
            <Segment textAlign='left'>
              <Text>
              <Header textAlign="center" style={{marginBottom:'0'}}>
                How Intrgr works
              </Header>
              <Header size='tiny' style={{padding:'0', 
                                          fontStyle:'italic', 
                                          textAlign:'center',
                                          margin:'auto', 
                                          fontWeight:'normal'}}>
                (intriguer)
              </Header>
              <List size="large">
               <List.Item>
                <List.Icon name='user' color='pink'/>
                <List.Content style={{fontWeight:'grey'}}>
                 Intrgr searches the web to bring the most relevant articles to you.
                </List.Content>
                </List.Item>
                <List.Item>
                 <List.Icon name='magic' color='pink' />
                 <List.Content>
                  The more you read on Intrgr the better the curation. 
                </List.Content>
                </List.Item>
                <List.Item>
                 <List.Icon name='globe' color='pink' />
                 <List.Content>
                   Get started by exploring the other sections.
                </List.Content>
                </List.Item>
              </List>
                </Text>
            </Segment>
           </Responsive>
           <Responsive {...Responsive.onlyComputer}>
            <div>
            <Message info>
              <Message.Header>How Intrgr Works</Message.Header>
              <Message.List>
                <Message.Item>
                  Intrgr searches the web to find the most relevant articles for you
                </Message.Item>
                <Message.Item>
                  As you read articles on Intrgr, we'll create your personal curation here in realtime
                </Message.Item>
                <Message.Item>
                  Start by exploring the other sections or enter the link of an article you already like below
                </Message.Item>
              </Message.List>
            </Message>
            <Form className='fluid segment'
                  onSubmit={this.handleSubmit}>
              <Form.Group>
              <Form.Input 
                  width={10}
                  placeholder='Enter the link of an article here'
                  name='link'
                  onChange={this.handleChange}
              />
          
              <Form.Button color='pink'>Get Started</Form.Button>
              </Form.Group>
            </Form>
            {this.state.link ? 
              <Message 
                info
                content={`Next time you can go to https://intrgr.com/${this.state.link}`}
              />
              : undefined
            }
            </div>
            </Responsive>
            </>
            );

  }


}

export default Tutorial = withRouter(Tutorial);

