import React, { Component } from 'react';
import { Responsive, Grid, Icon, Button,Segment, Menu, Header, Container } from 'semantic-ui-react';
import styled from 'styled-components';
import Futura from '../fonts/FUTURISE.ttf'
import ArticleInput from './ArticleInput';
import SendMessageModal from './SendMessageModal';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

const FixLink = styled.span`
             margin: auto;
            `;

class VibeShakeMenu extends Component {

  handleClick = (e, {name}) => name === 'Login' 
                                    ? this.props.history.push("/login")
                                    : 
                                    name === "Sign Up" 
                                    ? this.props.history.push("/signup") 
                                    : null;

  render() {
    const { authed, axiosClient } = this.props;
    const {pathname} = this.props.location;
    const loginBool = pathname === "/signup" || pathname === "/"; 
    return (
      <Menu size='mini' borderless style={{
          borderStyle:'none',
          boxShadow:'none',
          backgroundColor:'rgba(0,0,0,0.0)'}}>
      <Menu.Menu position='right' style={{paddingRight:'1.5em'}}>
                <>
                <FixLink>
                 <Link to='/inbox'>
                   <Icon.Group size='big'>
                    <Icon link color='pink' name='send' />
                    <Icon corner='bottom right' name='circle thin' color='gray' />
                   </Icon.Group>
                 </Link>
                 </FixLink>
                  <FixLink>
                  <Link to='/vlog' style={{padding:'1em'}}>
                  <Icon.Group size='big'>
                   <Icon link color='pink' name='camera' />
                   <Icon corner='bottom right' name='add' color='gray' />
                  </Icon.Group>
                  </Link>
                  </FixLink>
                  <FixLink>
                   <Link to='/profile' style={{paddingLeft:'.5em'}}>
                   <Icon link inverted color='pink' size='large' circular name='user'>
                   </Icon>
                   </Link>
                  </FixLink>
                  </>
      </Menu.Menu>
      </Menu>
    );

  }


}

export default VibeShakeMenu = withRouter(VibeShakeMenu);
