import React, { Component } from 'react';
import styled from 'styled-components';
import {Helmet} from "react-helmet";

const RemoveLinks = styled.span`
                    .ad {
                        display: none;
                    }
                    `;
class ArticleText extends Component {

    render() { 

        return (
                <RemoveLinks>
                <div dangerouslySetInnerHTML={{__html: this.props.html}}>
                </div>
                </RemoveLinks>
               );
    }
        

}


export default ArticleText;
