import React, { useEffect, useState, useContext } from 'react';
import { Button, List, Visibility, Loader, Container, Segment, Card, Header, Grid, Icon, Label, Menu } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import getRecommended from '../utils/getRecommended';
import createCards from '../utils/createCards';
import createVideoCards from './utils/createVideoCards';
import getCategory from './utils/getCategory';
import SignUp from './SignUp';
import Tutorial from './Tutorial'; import AuthContext  from '../auth-context';
import { getAxiosClient } from './utils/AxiosClient';

const CARDS_PER_ROW = 4;

const HorizontalScroll = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    
    .label {
      flex: 0 0 auto;
    }

`;

const card = (<Card
     href="/setup"
     key="intrgr_card"
     >
     <Card.Content>
        <Card.Header>
          We're searching the web right now.
        </Card.Header>
        <Card.Description>
         The web's a big place. As we find more articles we'll populate them here.
        </Card.Description>
     </Card.Content>
     <Card.Content extra textAlign="left">
     intrgr.com
     </Card.Content>
     </Card>
     );

const categoryMapping = {
    "home": 0,
    "general": 1,
    "politics": 2,
    "business": 3,
    "health": 4,
    "science": 5,
    "sports": 6,
    "tech": 7,
    "entertainment": 8,
    "gaming": 9,
    "lifestyle": 10
};


export default function MobileView(props) {
    const feedCategory = localStorage.getItem('feedCategoryName');
    const [axiosClient, setAxiosClient] = useState(getAxiosClient());
    const [authed, setAuthed] = useState(localStorage.getItem("authToken"));
    const [blockRefresh, setBlockRefresh] = useState(false);
    const [activeItem, setActiveItem] = useState(
      authed ? "home" : (feedCategory ? feedCategory : "politics")
    );
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [attempted, setAttempted] = useState(false);

    const history = useHistory();

    useEffect( () => {

      if(cards.length || loading)
        return

      populateHomeView(activeItem);
    }, [axiosClient]);

    const handlePassed = function (e, {calculations} ) {

      if (!calculations.percentagePassed || calculations.percentagePassed < .875 || loading || blockRefresh)
        return;

      setBlockRefresh(true);
      populateHomeView(activeItem, 'today', true);
      setTimeout(() => setBlockRefresh(false), 800);
    };

    const populateHomeView = function (categoryName, dateRange='today', scrollBack=false) {


        localStorage.setItem('feedCategoryName', categoryName);
        const categoryId = categoryMapping[categoryName];
        
        if (categoryId === 0) {

          if (!authed) {
            setCards([]); 
            return;
          }
          setLoading(true);
          getRecommended(axiosClient).then(candidates => {
              setCards(candidates);
              setAttempted(true);
              if(scrollBack)
                 window.scrollTo({ top: 0, behavior: 'smooth' });
              setLoading(false);
          });
        } else {
          setLoading(true);
          getCategory(axiosClient, categoryId, dateRange).then( candidates => {
                  setCards(candidates);
                  if(scrollBack)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  setLoading(false);
          });
        }
    };

    const handleItemClick = function (e,{name}) {
      setActiveItem(name); 
      populateHomeView(name);
      return;
    };

    return (
            <Grid centered  
                  padded
                  container
                  verticalAlign='middle'>
            <Grid.Row>
            <Grid.Column>
            <Menu secondary>
            <HorizontalScroll>
            <Menu.Item
             name='home'
             active={activeItem == 'home'}
             onClick={handleItemClick}
             >
             <Icon color={activeItem == 'home' ? 'pink' : undefined} name='home' />
             Home
            </Menu.Item>
            <Menu.Item
             name='politics'
             active={activeItem == 'politics'}
             onClick={handleItemClick}
             >
              <Icon color={activeItem == 'politics' ? 'pink' : undefined} name='law' />
              Politics
            </Menu.Item>
            <Menu.Item
             name='general'
             active={activeItem == 'general'}
             onClick={handleItemClick}
             >
              <Icon color={activeItem == 'general' ? 'pink' : undefined} name='newspaper outline' />
              General
            </Menu.Item>
            <Menu.Item
             name='lifestyle'
             active={activeItem === 'lifestyle'}
             onClick={handleItemClick}
             >
            <Icon color={activeItem == 'lifestyle' ? 'pink' : undefined} name='plane'/>
            Lifestyle
            </Menu.Item>
            <Menu.Item
             name='entertainment'
             active={activeItem == 'entertainment'}
             onClick={handleItemClick}
             >
              <Icon color={activeItem == 'entertainment' ? 'pink' : undefined} name='film' />
              Entertain
            </Menu.Item>
            <Menu.Item
             name='tech'
             active={activeItem == 'tech'}
             onClick={handleItemClick}
             >
            <Icon color={activeItem == 'tech' ? 'pink' : undefined} name='power off' />
            Tech
            </Menu.Item>
            <Menu.Item
             name='science'
             active={activeItem == 'science'}
             onClick={handleItemClick}
             >
            <Icon color={activeItem == 'science' ? 'pink' : undefined} name='flask' />
            Science
            </Menu.Item>
            <Menu.Item
             name='sports'
             onClick={handleItemClick}
             active={activeItem == 'sports'}
             >
            <Icon color={activeItem == 'sports' ? 'pink' : undefined} name='futbol' />
            Sports
            </Menu.Item>
            <Menu.Item
             name='business'
             active={activeItem == 'business'}
             onClick={handleItemClick}
             >
            <Icon color={activeItem == 'business' ? 'pink' : undefined} name='suitcase' />
            Business
            </Menu.Item>
            </HorizontalScroll>
            </Menu>
            <div style={{display:'flex', justifyContent:'center'}}>
            </div>
            {/*
            <Menu secondary>
            <Menu.Item
             onClick={handleItemClick}
                active={activeItem == 'vlogs'}
            >
            <Icon name='play'/>
             Vlogs
            </Menu.Item>
            </Menu>
            */}
            </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{paddingTop:'0', paddingBottom:'0'}}>
            <Loader size='large' active={loading} style={{top:'15vw'}} />
            <Container text style={{paddingTop:'0'}}>
            <Segment padded basic style={{paddingTop:'0', minWidth:'22em'}}>
            <Menu widths={2} compact style={{padding:'.0em', marginBottom:'1em'}}>
            <Menu.Item
             onClick={handleItemClick}
                active={true}
            >
             Vlogs
            </Menu.Item>
            <Menu.Item
             onClick={handleItemClick}
                active={activeItem == 'vlogs'}
            >
             Articles
            </Menu.Item>
            </Menu>
            {(activeItem === "home" && authed && !cards.length) ? 
              <Tutorial /> : null
            }
            {(activeItem === 'home' && !authed)  ? 
            (<>
              <Header textAlign="center">
                Sign up for your personalized Home
              </Header>
              <SignUp style={{margin:'auto'}} /> 
            <Segment padded textAlign="left">
              <List size="large">
               <List.Item>
                <List.Icon name='user' color='pink'/>
                <List.Content>
                <List.Header>
                  Always in the know
                </List.Header>
                <List.Description>
                Be kept up to date with the most relevant articles for you from around the web, right here
                </List.Description>
               </List.Content>
                </List.Item>
                <List.Item>
                 <List.Icon name='globe' color='pink' />
                 <List.Content>
                  <List.Header>
                    Share
                  </List.Header>
                  <List.Description>
                    Discover or post your own articles to discuss with others who have the same interests
                  </List.Description>
                </List.Content>
                </List.Item>
                <List.Item>
                 <List.Icon name='magic' color='pink' />
                 <List.Content>
                  <List.Header>
                    Dive Deep
                  </List.Header>
                  <List.Description>
                    Find all the related articles to any you discover or submit
                  </List.Description>
                </List.Content>
                </List.Item>
              </List>
            </Segment></>) : null

            }
            <Visibility
              onUpdate={handlePassed}
            >
            {createVideoCards()}
            {createCards(cards, "mobileView")}
            </Visibility>
            {(loading && cards.length) ?  
            (<Segment basic padded>
              <Loader size='large' active={true} />
             </Segment>): null
            }
            </Segment>
            </Container>
            </Grid.Row>
            </Grid>
            )  
}
