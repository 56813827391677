
import React from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';
import { EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton }  from 'react-share';

export default function dropdown ({urlToken, firstName, lastName}, icon='ellipsis vertical big', fontSize=undefined) { return (<Dropdown icon={icon}>
                   <Dropdown.Menu style={{zIndex:'12'}} direction='left'>
                      <Dropdown.Header content='Share'/>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <TwitterShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/p/${urlToken}`}>
                          <Icon name='twitter' />
                        </TwitterShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <FacebookShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/p/${urlToken}`}>
                          <Icon name='facebook' />
                        </FacebookShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <LinkedinShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/p/${urlToken}`}>
                          <Icon name='linkedin' />
                        </LinkedinShareButton>
                      </Dropdown.Item>
                      <Dropdown.Item style={{marginRight:'0'}}>
                        <EmailShareButton 
                           style={{paddingLeft:'33%', paddingRight:'33%', textAlign:'center'}}
                           url={`https://intrgr.com/p/${urlToken}`}
                           subject={`Check out ${firstName} ${lastName} on intrgr.com`}
                           body={`What do you think?\n\n`}
                           >
                          <Icon name='mail' />
                        </EmailShareButton>
                      </Dropdown.Item>
                   </Dropdown.Menu>
                   </Dropdown>);
}
