import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { Transition, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

const Large = styled.span`
          font-size: 150%;
`;



class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcome: true,
      visible: false,
      setupVisible: false

    };

  } 

  componentDidMount () {
    setTimeout(() => { 
                  this.setState({visible: true});
               }, 600);
    setTimeout(() => { 
                  this.setState({visible: false});
               }, 3500);
    setTimeout(() => { 
                  this.setState({setupVisible: true});
               }, 6800);
  }

  render() {
    return (<Grid textAlign='center' style={{height: '50vh'}} verticalAlign='middle'>
            <Grid.Row>
            <Grid.Column style={{ maxWidth: "50vw" }} >
            <Transition visible={this.state.visible} animation='fade' duration='3000'> 
            <Header as='h1'> 
            <Large>
              Welcome
            </Large>
            </Header> 
            </Transition>
            <Transition visible={this.state.setupVisible} animation='fade' duration='10000'> 
                <Redirect to="/" />
            </Transition> 
            </Grid.Column>
            </Grid.Row>
            </Grid>);
  }
}

export default Setup;
