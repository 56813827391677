import React, { Component } from 'react';
import ArticleView from './components/ArticleView';
import { AxiosClient, getAxiosClient } from './utils/AxiosClient';
import storeSession from './utils/storeSession';
import styled from 'styled-components';
import { Divider, Segment, Grid, Rail, Container } from 'semantic-ui-react';
import Common from './components/Common';

class Article extends Component {
    constructor (props){
        super(props)
        this.state = {
            AxiosClient: getAxiosClient(),
            authKey: false,
            width: window.innerWidth
        };
        window.addEventListener('resize', this.updateWindowWidth);
    }

    componentDidMount(){ 
        if (this.props.match.params.name.length >= 14){
            var authKey = this.props.match.params.name.substring(8, 14);
            storeSession(authKey).then( (result) => {
                                        if(result)
                                            return this.setState({
                                                authKey: true,
                                                AxiosClient: 
                                                new AxiosClient(result)
                                            });
                                        })
                                 .catch( () => console.log("Failed") );
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

    updateWindowWidth = () => {
        this.setState({width: window.innerWidth});
    }

    render() {
        const { AxiosClient, width } = this.state;
        var articleKey = this.props.match.params.name.substring(0,8);
        return (
                  <Common width={width}>
                  <ArticleView articleKey={articleKey}
                               AxiosClient={AxiosClient}
                               width={width}/>
                  </Common>
                  );
    }

}

export default Article;
