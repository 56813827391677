import React, {useState} from 'react';
import {withRouter} from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, List, Button, Form, Message, Header, Segment, Container } from 'semantic-ui-react';

const Font = styled.span`
              font-family: Verdana, sans-serif; 
             `;


export default function AuthInvite (props) {
  const [link, setLink] = useState(null);
  const history = useHistory();

  const handleChange = (e, {value} ) => {
      setLink(value); 
  }

  const handleSubmit = () => {
    history.push(`/${link}`);
  }

    return (
            <Segment basic>
            <Header as='h1' textAlign='center'>
            Welcome
            </Header>

        {/*<Message info>*/}
                    <Segment raised>
                <Font>
                <List>
                    <List.Item>
                    <Header style={{paddingBottom:'2em'}}>
                        Register to get:
                    </Header>
                    <List>
                     <List.Item style={{paddingBottom:'2em'}}>
                      <Icon style={{paddingRight:'1em'}} name='globe' color='pink' size='huge'/>
                      <List.Content>
                     <Header>
                      To be kept up to date with the most relevant articles for you from around the web
                     </Header>
                     </List.Content>
                    </List.Item>
                    <List.Item icon style={{paddingBottom:'2em'}}>
                      <Icon style={{paddingRight:'1em'}} name='user' color='pink' size='huge'/>
                    <List.Content>
                     <Header>
                      A personal curation which improves and updates in realtime as you read more articles
                     </Header>
                      </List.Content>
                    </List.Item>
                    <List.Item style={{paddingBottom:'2em'}}>
                      <Icon style={{paddingRight:'.75em'}} name='group' color='pink' size='huge'/>
                    <List.Content>
                      <Header>
                      To exchange comments with the people who are interested in the same articles as you
                      </Header>
                    </List.Content>
                    </List.Item>
                  </List>
                 </List.Item>
                </List>
                </Font>
                </Segment>
        {/*</Message>*/}
            <Segment textAlign='center' basic>
            <Button basic color="pink" name="signin">
                Sign Up
            </Button>
            </Segment>
            </Segment>
            );

}

