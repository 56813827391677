import axios from 'axios';
import { API } from '../config';
export default function getVideoByToken (token) {
   return axios.get(`${API}/video/${token}`).then( (response) => {
       if(response.status === 200){
           console.log(response.data.videoMetadata);
           return response.data.videoMetadata; 
       }

       return [];

   });
}
