import React, { Component } from 'react'; 
import { Form, Button, Message } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { API } from './config';
import styled from 'styled-components';
import axios from 'axios';
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = false;

class Login extends Component { 

  constructor (props) {
      super(props)
      this.state= { 
        errors: {},
        validated: true,
        success: undefined,
        submitted: false,
        errorCode: null
      }
      this.validators = { 
        email: () => {
            const regex=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !this.state.email.match(regex) ? "Please enter a valid email."
                                                  : null;
        },
        password: () =>  { 
             const regex = /^[a-zA-Z0-9]\S+$/;
             return (!this.state.password || 
                     !this.state.password.match(regex) )  ? 
                     "Please enter a valid password." :
                     null;
        }
      };
  }

  handleChange = (e, {name, value} ) => this.setState({[name]: value,
                                                       validated: false,
                                                       errorCode: null});
  handleLogin = () => {
      const { email, password, success} = this.state;
      axios.post(`${API}/user/login`, {email: email, password: password})
        .then( (resp) => { localStorage.setItem("authToken", resp.data.accessToken);
                           localStorage.setItem("profileToken", resp.data.profileToken);
                           this.setState({success: true,
                                          loading: false});
        }).catch( (error) => {
                            const { status } = error.response;
                            this.setState({success: false,
                                           loading: false,
                                           errorCode: error});
                            });
      if(success === undefined)
        this.setState({loading: true});
  }

  getFieldError (field) {
    const { errors } = this.state;

    if (!errors[field])  
      return null;

    return { content: errors[field],
             pointing: "below"
            };

  }

  componentDidUpdate() {


      if(this.state.validated)
          return;

      const errors = {};
      const check_fields = {}
      Object.keys(this.validators)
            .filter( (key) => this.state[key] )
            .map( (key) => {
                const error = this.validators[key]();
                if( error ){
                    errors[key] = error;
                }
             });

      this.setState({errors: errors,
                     validated: true});
  }

  render () {
    if(this.state.success === true)
      return <Redirect to="/" /> 

    return (
    <Form style={{textAlign:'center'}} onSubmit={this.handleLogin} loading={this.state.loading} warning={false}>
      <Form.Field>
        <Form.Input placeholder='Email'
                    name='email'
                    type='email' 
                    onChange={this.handleChange}
                    error={this.getFieldError('email')}  />
      </Form.Field> 
      <Form.Field>
        <Form.Input placeholder='Password' 
               name='password'
               onChange={this.handleChange}
               type='password'
               error={this.getFieldError('password')}  />
      </Form.Field> 
      {  
          this.state.errorCode ? 
            <Message 
             negative 
             header="Login Failed" 
             content={this.state.errorCode === 400 ? "Your email or password is incorrect." : "Seems like something went wrong."}
            /> : null
      }
      <Form.Field control={Button} disabled={Object.keys(this.state.errors).length !== 0} color='pink'>
        Login
      </Form.Field>
    </Form>
    );
  }


}

export default Login;

