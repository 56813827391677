import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Header, Icon, Popup, Grid, Segment, Card, Image, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import VideoView from '../VideoView';
import dropdown from '../DropDown';
import ReactPlayer from 'react-player';


const imageUrl = function ({hasThumb, urlToken}) {
    let imageUrl = '../../../assets/user_placeholder.png';
    if (hasThumb) 
       imageUrl = `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${urlToken}/thumbnail.png`;
    return imageUrl;

};

function createVideoLink(videoToken, profileToken) {
    return `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/video/${videoToken}/video.mp4`;
};

function createVideoThumbnail(videoToken, profileToken) {
    return `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/video/${videoToken}/thumbnail.png`;
};

const cardConfig = {
  regular: {
    maxCardTitleWithImage: 100,
    maxCardTitle: 200,
    style: {
      maxHeight:"75vh",
      minHeight:"20em",
      minWidth:"13em",
      maxWidth:"15em"
    },
  },
  mobileView: {
    maxCardTitleWithImage: 80,
    maxCardTitle: 200,
    style: {
      maxHeight:"48vh",
      minHeight:"26em",
      minWidth:"13em",
    },
  },
};

export default function createVideoCards(videoArray=[1,], profileToken, username, style="regular") {
  console.log('jarjar', videoArray);
  return videoArray.map( (video, index) => {
        const videoToken = video[0];
        console.log('hey', createVideoLink(videoToken, profileToken));
        return (<Card 
                  link
                  key={`${index}`}
                  style={cardConfig[style]}
                >
                <Image 
                  src={createVideoThumbnail(videoToken, profileToken)}
                />
                  <div
                    style={{
                         display:'flex',
                         justifyContent:'center',
                         position:'absolute', 
                          top:'50%',
                          left:'50%',
                          height:'100%',
                          width:'100%',
                          transform:'translate(-50%,-50%)',
                          }}
                    >
                    <Link style={{display:'flex', justifyContent:'center'}} to={{pathname: `/v/${videoToken}`, state:{username}}}>
                    <Icon link size='huge' color="white" inverted style={{margin:'auto', textShadow:'2px 2px 2px #E03977'}} name='play'/>
                    </Link>

                 </div>
                  <Card.Content style={{
                                        backgroundColor:'rgba(0,0,0,0.25)',
                                        position:'absolute', 
                                        bottom:'.5em',
                                        borderStyle:'none'}}>
                  <span style={{color:'white', position:'absolute', bottom:'.5em', right:'.5em'}}>
                  {dropdown(video)}
			      </span>
                  <Header style={{marginTop:'0', textAlign:'left', color:'white', maxWidth:'95%'}}>
                    This is me going to the beach and wondering where everyone went.
                  </Header>
                 </Card.Content>
                </Card>);
  });
}
