import React, { Component } from 'react'; 
import SiteMenu from './SiteMenu';
import {Menu, Container, Divider, Responsive} from 'semantic-ui-react';
import {AxiosClient} from '../utils/AxiosClient';

class Common extends Component {
  render () {
    return (
        <>
        <SiteMenu prevLink={this.props.prevLink} authed={localStorage.getItem("authToken")}/>
        {this.props.children}
        </>
    );
  }

}

export default Common;
