import React, { Component } from 'react';
import { Icon, Segment, Image, Header } from 'semantic-ui-react';
import { EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton }  from 'react-share';
import styled from 'styled-components';
import axios from 'axios';

const HeaderStyle = styled.div`
                    margin-top: 1rem;
                    margin-bottom: 2rem;
                    `;

const SocialButtons = ({url_token, title}) => (
    <div style={{paddingTop:'.5em'}}>
      <TwitterShareButton 
         url={`https://intrgr.com/a/${url_token}`}>
        <Icon name='twitter' />
      </TwitterShareButton>
      <FacebookShareButton 
        url={`https://intrgr.com/a/${url_token}`} >
       <Icon name='facebook' />
      </FacebookShareButton>
      <LinkedinShareButton 
        url={`https://intrgr.com/a/${url_token}`}>
       <Icon name='linkedin' />
      </LinkedinShareButton>
      <EmailShareButton 
         url={`https://intrgr.com/a/${url_token}`}
         subject="Thoughts?"
         body={`${title}\n\n`}
         >
        <Icon name='mail' />
      </EmailShareButton>
      </div>
);

const dateHumanFormat = function(dateString) {

  if(!dateString)
    return "";
  
  const dateArray = dateString.split(/\D/);
  return `${dateArray[1]}-${dateArray[2]}-${dateArray[0]} ${dateArray[4]}:${dateArray[5]} GMT`
};

class ArticleHeader extends Component {
    render () {
        if(!this.props.title)
            return null;
        const {title, author, date_published} = this.props;
        return( <HeaderStyle>
                <Header as='h1'>{title}</Header>
                <Header sub> 
                {author} | {dateHumanFormat(date_published)}
                </Header>
                {SocialButtons(this.props)}
                </HeaderStyle>
        );

    }


}




export default ArticleHeader;
