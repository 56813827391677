import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

const HeaderStyle = styled.div`
                    margin-top: 1rem;
                    margin-bottom: 2rem;
                    `;

class RecommendedHeader extends Component {
    render () {

        return(
                <HeaderStyle>
                <Header textAlign='center' as='h1'>
                 { "Related" }
                </Header>
                </HeaderStyle>
        );
    }


}




export default RecommendedHeader;
