import React, { Component } from 'react'
import { Menu, Segment, Icon } from 'semantic-ui-react'
import DateMenu from './DateMenu'

const categoryMapping = {
    "home": 0,
    "general": 1,
    "politics": 2,
    "business": 3,
    "health": 4,
    "science": 5,
    "sports": 6,
    "tech": 7,
    "entertainment": 8,
    "gaming": 9,
    "lifestyle": 10
};

export default class CategoryMenu extends Component {
      constructor(props) {
        super(props);
        const feedCategoryId = parseInt( localStorage.getItem('feedCategoryId') );
        this.state = { 
          activeItem:  feedCategoryId > 0 ? feedCategoryId 
                                          : categoryMapping["home"]
        };
      }

      populateWithDateRange = (dateRange) => {
        const categoryId = this.state.activeItem;
        this.props.populateHomeView(categoryId, dateRange);
      };

      handleItemClick = (e, { name }) => { 
          const categoryId = categoryMapping[name];
          this.setState({activeItem: categoryId}, () => {
            this.props.populateHomeView(categoryId);
          });
      };

      render() {
              const { activeItem } = this.state;

              return (    <div style={{paddingBottom:".5em"}}>
                          <Menu fluid stackable widths={10} pointing secondary icon='labeled'>
                            <Menu.Item
                              name='home'
                              active={activeItem === categoryMapping['home']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='home' />
                            Home
                            </Menu.Item>
                            <Menu.Item
                              name='politics'
                              active={activeItem === categoryMapping['politics']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='law' />
                            Politics
                            </Menu.Item>
                            <Menu.Item
                              name='entertainment'
                              active={activeItem === categoryMapping['entertainment']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='film' />
                            Entertainment
                            </Menu.Item>
                            <Menu.Item
                              name='health'
                              active={activeItem === categoryMapping['health']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='plus square' />
                            Health
                            </Menu.Item>
                            <Menu.Item
                              name='general'
                              active={activeItem === categoryMapping['general']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='newspaper outline' />
                            General
                            </Menu.Item>
                            <Menu.Item
                              name='tech'
                              active={activeItem === categoryMapping['tech']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='power off' />
                            Tech
                            </Menu.Item>
                            <Menu.Item
                              name='science'
                              active={activeItem === categoryMapping['science']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='flask' />
                            Science
                            </Menu.Item>
                            <Menu.Item
                              name='sports'
                              active={activeItem === categoryMapping['sports']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='futbol' />
                            Sports
                            </Menu.Item>
                            <Menu.Item
                              name='lifestyle'
                              active={activeItem === categoryMapping['lifestyle']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='plane' />
                            Lifestyle
                            </Menu.Item>
                            <Menu.Item
                              name='business'
                              active={activeItem === categoryMapping['business']}
                              onClick={this.handleItemClick}
                            >
                            <Icon name='suitcase' />
                            Business
                            </Menu.Item>
                          </Menu>
                          {
                            (activeItem === categoryMapping['home']) ? <div style={{paddingBottom:'.5em'}}/> :
                             <DateMenu populateHomeView={this.populateWithDateRange.bind(this)} />
                          }
                         </div>
              );
      }
}
