import React, { Component, useState, useEffect } from 'react'; 
import { Redirect, Link, useHistory } from 'react-router-dom';
import { Image, Comment, Button, Form, Message, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import {withRouter} from 'react-router';
import { API } from '../config';
import axios from 'axios';
import moment from 'moment';

const DEFAULT_IMG='https://react.semantic-ui.com/images/wireframe/square-image.png';

export default function CommentView (props) { 
  const isAuthed = !!localStorage.getItem("authToken");
  const [lastNonce, setLastNonce] = useState(null);
  const [comments, setComments] = useState([]);
  const [errors, setErrors] = useState({});
  const [authMsg, setAuthMsg] = useState(false);
  const [failureCode, setFailureCode] = useState();
  const [refresh, setRefresh] = useState(true);
  const [validated, setValidated] = useState(true);
  const [comment, setComment] = useState("");
  const [success, setSuccess] = useState();
  const [replyState, setReplyState] = useState("");
  const [axiosClient, setAxiosClient] = useState(props.axiosClient);


  const history = useHistory();

  const inputs = [comment];
  const mapInput= {"comment": setComment};

  const handleChange = (e, {name, value} ) => { 
    mapInput[name](value);
  }

  const validators = { 
    comment: () => {
      if (!comment) { return undefined;}
      if (comment.length > 1000) {
        return "Your comment has to be less than 1000 characters";
      }
      return undefined;
    },
    badResponse: () => {
      if (failureCode == 422) {
        return 422;
      }
      return undefined;
    }
  };

  const getFieldError = function (field) {

    if (!errors[field])  
      return null;

    return { content: errors[field],
             pointing: "below"
            };

  };

  useEffect( () => {

    if (props.axiosClient) {
        setAxiosClient(props.axiosClient);
        if (refresh && props.videoToken ||
            lastNonce !== props.updateNonce) {

          getComments();
          setRefresh(false);

          if(props.updateNonce)
            setLastNonce(props.updateNonce);
        }
      }
      const errors = {};
      Object.keys(validators)
            .forEach( (key) => {
                const error = validators[key]();
                if( error ){
                    errors[key] = error;
                }
             });

      setErrors(errors);

}, [refresh, failureCode, comments, validated, props.videoToken, props.axiosClient, props.updateNonce]);

  const createErrorMessage = function() {

      if (authMsg) {
          return (<Message floating info>
              {<a style={{color:"#E03997"}} href='/signup'>Sign Up</a>} or {<a style={{color:"#E03997"}} href='/login'>Login</a>} to post a comment 
                  </Message>)
      }
    if (!Object.keys(errors).length) {
      return null;
    }

    if (errors["badResponse"] == 422) {
          return (<Message floating info>
              {<Link to="/profile" style={{color:"#E03997"}}>Click here</Link>} to setup your profile to post comments.
                  </Message>)

     }
   
    let errorMsg = "Seems like something went wrong.";


     errorMsg = errors[ Object.keys(errors)[0] ]

    return (<Message negative>
    <Message.Header> 
        Your comment couldn't be posted 
    </Message.Header>
    {errorMsg}
    </Message>)

  }

  const handleSubmit = function (parentToken) {

    if (!isAuthed) {
        setAuthMsg(true);
        return;
    }

    axiosClient.post(`video/${props.videoToken}/comment`, {
    comment,
        parentToken
  }).then(resp => {
        if ( !localStorage.getItem("authToken") ) {
            setAuthMsg(true); 
            return;
        }
    setSuccess(true);
        setRefresh(true);
        setReplyState("");
        setComment("");
  }).catch(err => {
        setFailureCode(err.response.status);
        console.log(err);
    });
  };

  const handleReply = function(replyToken, firstName, lastName) {
        setComment("");
    if (replyState === replyToken) {
      setReplyState("");
      return;
    }
        if (firstName || lastName) {
            setComment(`@${firstName} ${lastName} `);
        }
    setReplyState(replyToken);
    return;
  };

    const htmlDecode = function(input) {
          var doc = new DOMParser().parseFromString(input, "text/html");
          return doc.documentElement.textContent;
    };

   const goToProfile = function({profileToken}) {
       return history.push(`/p/${profileToken}`);
   }

  const createComment = function (commentObj) {
    const imageUrl = !commentObj.profileHasThumb ? DEFAULT_IMG
                : `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${commentObj.profileToken}/thumbnail.png`;
    return (<Comment key={commentObj.urlToken} style={{clear:'both'}}>
               <Image avatar circular>
                <Link as="img" to={`/p/${commentObj.profileToken}`}>
                <img src={imageUrl}/>
                </Link>
               </Image>
              <Comment.Content>
                <Comment.Author as="a" onClick={() => goToProfile(commentObj)}>
                  {`${commentObj.firstName} ${commentObj.lastName}`}
                </Comment.Author>
                <Comment.Metadata>
                  {moment(commentObj.created_at).fromNow()}
                </Comment.Metadata>
                <Comment.Text>
                  { htmlDecode(commentObj.text) }
                </Comment.Text>
                <Comment.Actions>
                  <Comment.Action onClick={() => { if (commentObj.parentToken)
                                                     return handleReply(commentObj.urlToken, 
                                                                        commentObj.firstName, 
                                                                        commentObj.lastName);

                                                   return handleReply(commentObj.urlToken);
                                                 }
                                           }>
                     Reply
                  </Comment.Action>
                </Comment.Actions>
                 { replyState === commentObj.urlToken ?
                  (<Form reply style={{maxHeight:'5em', maxWidth:'66%', marginBottom:'4em'}}>
                    { createErrorMessage() }
                    <Form.TextArea 
                      placeholder="Add your reply here..."
                      name="comment"
                      onChange={handleChange}
                      value={comment}
                      style={{maxHeight:'5em'}}/>
                    <Button size='tiny' floated='left'
                      content='Add Reply'
                      labelPosition='left'
                      icon='edit'
                      onClick={() => handleSubmit(commentObj.urlToken)}
                      disabled={errors["comment"]}
                      primary
                    />
                  </Form>) : null
                }
              </Comment.Content>
              { commentObj.children.length ?  
                (<Comment.Group>
                {
                  commentObj.children.map( (childComment) => {
                    return createComment(childComment);
                  })
                }
                </Comment.Group>) : null
              }
              </Comment>
            );
  };

  const getComments = function () {
    axiosClient.get(`video/${props.videoToken}/comment`).then(({data}) => {
      setSuccess(true);
      console.log(`meep ${JSON.stringify(comments)}` );
      setComments(data.comments);
    }).catch(err => console.log(err));
  };

  if (!comments) {
    return null;
  }

  return ( 
  <Comment.Group>
  {
    comments.map( (comment) => {
      return createComment(comment);
    })
  }
  </Comment.Group>
  );

};


