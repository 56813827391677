import React, { Component } from 'react';
import { Segment, Container, Grid, Loader } from 'semantic-ui-react';
import SignUp from './components/SignUp';
import SubSignUp from './components/SubSignUp';
import SiteMenu from './components/SiteMenu';
import Login from './components/Login';
import Home from './Home';
import { Redirect } from 'react-router-dom';
import { getAxiosClient } from './utils/AxiosClient';


class Landing extends Component {

  render() {

    const authToken = localStorage.getItem('authToken'); 
    if (authToken) {
        const axiosClient = getAxiosClient(); 
        return(<Home AxiosClient={axiosClient}/>);
    }

    return(
      <Segment basic verticalAlign='middle' textAlign='center' style={{marginTop:'10%'}}>
      <Container text>
      <Grid container centered verticalAlign='middle'>
        <Grid.Row>
        <Grid.Column style={{ maxWidth: 450 }}> 

         { !this.props.login ? 
           <SignUp /> : 
           <Login /> 
         }
        </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column>
         { this.props.login ? 
          <span /> :  
          <SubSignUp />
         }
        </Grid.Column>
        </Grid.Row>
      </Grid>
        </Container>
        </Segment>
    );
  }
}


export default Landing;
