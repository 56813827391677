
export default function getRecommended (AxiosClient) {
   return AxiosClient.get("recommend/12").then( (response) => {

       if(response.status === 200)
           return response.data.candidates; 

       return [];

   });
}
