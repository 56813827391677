import React, { Component, useState, useEffect } from 'react'; 
import { Redirect, Link, useHistory } from 'react-router-dom';
import { Label, Input, Container, Segment, Header, Image, Comment, Button, Form, Message, TextArea } from 'semantic-ui-react';
import TextareaAutosize from 'react-autosize-textarea';
import styled from 'styled-components';
import {withRouter} from 'react-router';
import { API } from '../config';
import axios from 'axios';
import moment from 'moment';

const VerticalScroll = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }

`;

const DEFAULT_IMG='https://react.semantic-ui.com/images/wireframe/square-image.png';
const TEXT_AREA_COL=30;

export default function CommentView (props) { 
  const isAuthed = !!localStorage.getItem("authToken");
  const [lastNonce, setLastNonce] = useState(null);
  const [comments, setComments] = useState([]);
  const [errors, setErrors] = useState({});
  const [authMsg, setAuthMsg] = useState(false);
  const [failureCode, setFailureCode] = useState();
  const [refresh, setRefresh] = useState(true);
  const [validated, setValidated] = useState(true);
  const [text, setText] = useState("");
  const [success, setSuccess] = useState();
  const [replyState, setReplyState] = useState("");
  const [axiosClient, setAxiosClient] = useState(props.axiosClient);
  const [rows, setRows] = useState(1);


  const history = useHistory();

  const validators = { 
    text: () => {
      if (!text) { return undefined;}
      if (text.length > 1000) {
        return "Your message has to be less than 1000 characters";
      }
      return undefined;
    },
    badResponse: () => {
      if (failureCode == 422) {
        return 422;
      }
      return undefined;
    }
  };

  const getFieldError = function (field) {

    if (!errors[field])  
      return null;

    return { content: errors[field],
             pointing: "below"
            };

  };

  useEffect( () => {
    console.log('yo', rows);
  }, [rows]);

  useEffect( () => {

    if (props.axiosClient) {
        setAxiosClient(props.axiosClient);
        if(props.username && !comments.length) {
          getComments();
        }
      }
      const errors = {};
      Object.keys(validators)
            .forEach( (key) => {
                const error = validators[key]();
                if( error ){
                    errors[key] = error;
                }
             });

      setErrors(errors);

}, [failureCode, validated, props.username, props.axiosClient]);

  const createErrorMessage = function() {

      if (authMsg) {
          return (<Message floating info>
              {<a style={{color:"#E03997"}} href='/signup'>Sign Up</a>} or {<a style={{color:"#E03997"}} href='/login'>Login</a>} to post a comment 
                  </Message>)
      }
    if (!Object.keys(errors).length) {
      return null;
    }

    if (errors["badResponse"] == 422) {
          return (<Message floating info>
              {<Link to="/profile" style={{color:"#E03997"}}>Click here</Link>} to setup your profile to post comments.
                  </Message>)

     }
   
    let errorMsg = "Seems like something went wrong.";


     errorMsg = errors[ Object.keys(errors)[0] ]

    return (<Message negative>
    <Message.Header> 
        Your comment couldn't be posted 
    </Message.Header>
    {errorMsg}
    </Message>)

  }

  const handleSubmit = function () {

    if (!isAuthed) {
        setAuthMsg(true);
        return;
    }

    axiosClient.post(`profile/message/${props.username}`, {
      text
  }).then(resp => {
        if ( !localStorage.getItem("authToken") ) {
            setAuthMsg(true); 
            return;
        }
        setSuccess(true);
        history.push({
            pathname: `/inbox/${props.username}`,
            state: {
              thumbUrl: props.thumbUrl,
              prevLink: props.prevLink
            }
        });
        //window.location.reload();
  }).catch(err => {
        setFailureCode(err.response.status);
        console.log(err);
    });
  };

  const htmlDecode = function(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

   const goToProfile = function({profileToken}) {
       return history.push(`/p/${profileToken}`);
   }

  const createComment = function (commentObj) {
    const imageUrl = !commentObj.hasThumb ? DEFAULT_IMG
                : `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${commentObj.profileToken}/thumbnail.png`;
    return (
                
              <Comment key={commentObj.urlToken} style={{clear:'both', marginTop:'.77em'}}>
               <Image avatar circular>
                <Link as="img" to={`/p/${commentObj.profileToken}`}>
                <img src={imageUrl}/>
                </Link>
               </Image>
              <Comment.Content>
                <Comment.Author as="a" onClick={() => goToProfile(commentObj)}>
                  {`${commentObj.username}`}
                </Comment.Author>
                <Comment.Metadata>
                  {moment(commentObj.createdAt).fromNow()}
                </Comment.Metadata>
                <Comment.Text>
                  { htmlDecode(commentObj.text) }
                </Comment.Text>
                {/* { replyState === commentObj.urlToken ?
                  (<Form reply style={{maxHeight:'5em', maxWidth:'66%', marginBottom:'4em'}}>
                    { createErrorMessage() }
                    <Form.TextArea 
                      placeholder="Add your reply here..."
                      name="comment"
                      onChange={handleChange}
                      value={comment}
                      style={{maxHeight:'5em'}}/>
                    <Button size='tiny' floated='left'
                      content='Add Reply'
                      labelPosition='left'
                      icon='edit'
                      onClick={() => handleSubmit(commentObj.urlToken)}
                      disabled={errors["comment"]}
                      primary
                    />
                  </Form>) : null
                }
                */}
              </Comment.Content>
              </Comment>
            );
  };

  const getComments = function () {
    axiosClient.get(`profile/message/${props.username}`).then(({data}) => {
      setSuccess(true);
      setComments(data.messages.reverse());
    }).catch(err => console.log(err));
  };

  if (!comments) {
    return null;
  }


  return ( 
  <Container style={{paddingTop: '1em'}}>
  <Segment.Group>
  <Segment textAlign='left' 
   style={{
     boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
     maxHeight:'80vh'
   }}>
  <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
  <Image size='mini' avatar src={props.thumbUrl}/>
  <Header as='h3' style={{margin:'0'}}>
  {`${props.username}`}
  </Header>
  </div>
  </Segment>
  <Segment style={{paddingTop:'0'}}>
  <Comment.Group>
  <VerticalScroll style={{maxHeight:'60vh'}}>
  {
    comments.map( (comment) => {
      return createComment(comment);
    })
  }
  </VerticalScroll>
  </Comment.Group>
  </Segment>
  <Segment basic textAlign='center' style={{paddingTop:'0'}}>
      <div style={{display:'flex', margin:'auto', justifyContent:'center', paddingTop:'0'}}>
      <TextareaAutosize
                onChange={({target}) => setText(target.value)}
                rows={2}
                style={{
                        fontFamily: 'Arial, Helvetica, sans-serif',
                        fontWeight: '480',
                        fontSize: '1.05em',
                        backgroundColor: '#F4F4F4',
                        resize:'none',
                        padding:'.25em',
                        border: 'hidden',
                        minWidth:'80%',
                        borderRight:'none',
                        paddingTop:'.5em',
                        paddingBottom:'.5em',
                        paddingLeft:'1em',
                        outline:'none',
                        borderBottomLeftRadius:'1.0em',
                        borderTopLeftRadius:'1.0em'}} />
      <div style={{border:'hidden', 
                   display:'flex',
                   alignItems:'flex-end',
                        backgroundColor: '#F4F4F4',
                   fontFamily: 'Verdana, sans-serif',
                   fontSize:'1.05em',
                   fontStyle:'bold',
                   justifyContent:'center',
                   borderLeft:'none',
                   borderTopRightRadius:'1.0em', 
                   borderBottomRightRadius:'1.0em'}}>
      <Link onClick={handleSubmit}>
      <Header style={{marginRight:'1em', marginBottom:'.33em'}} size='tiny' color='pink'>
        Send
      </Header>
      </Link>
      </div>
      </div>
  </Segment>
  </Segment.Group>
  </Container>
  );

};


