import React, { Component } from 'react';
import { Grid,Placeholder, Segment, Card, Feed, Container, Rail } from 'semantic-ui-react';
import getRelated from './utils/getRelated';
import createCards from '../utils/createCards';
import styled from 'styled-components';

class RecommendedContent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            related: [],
            attempted: false
        };
    }


    render () {
      const { related, attempted } = this.state;
      const { viewed_url, articleKey } = this.props;

      if (!attempted) {
          getRelated(articleKey).then( (resp) => {
              if(resp) { 
                  this.setState({related: resp,
                                 attempted: true});
              }
           }).catch( (err) => {
               this.setState({attempted: true});
           });
      }

      var cards = [];
      if (related.length) {
        const size = this.props.size ? this.props.size : 'regular';
        var cards = createCards( related.filter( (article) => {
                                   if(!article.url_token) {
                                      return article.url !== viewed_url;
                                   }
  
                                   return article.url_token !== articleKey;
                                  }), size);
  
       //} else if (AxiosClient) {
          //if( localStorage.getItem("authToken") )
          //  cards.push( <Card
          //               header="See Nothing Here?"
          //               description="intrgr.com can only curate content if you view enough articles. Click here to learn how to view more."
          //               extra="intrgr.com"
          //               href="/setup"
          //               key="intrgr_card"
          //               />);
          //else
          //  cards.push( <Card
          //               header="Oops, You Got Logged Out!"
          //               description="Click here to not miss anymore curated content."
          //               extra="intrgr.com"
          //               href="/login"
          //               key="intrgr_card"
          //               />);
      } else {   
            cards.push( <Card
                         header="This article is very unique."
                         description="When similar articles are found they'll appear here"
                         extra="intrgr.com"
                         href="/"
                         key="intrgr_card"
                         />);
            for(var i=0; i < 10; i++)
              cards.push( ArticlePlaceholder(i) );

          }

          const { cardsPerView } = this.props;
          return(
              <Grid centered container verticalAlign='middle' textAlign='center'>
              <Grid.Column >
              { cardsPerView < 5 ?
              (<Card.Group centered itemsPerRow={cardsPerView}>
              {cards}
              </Card.Group>) :
              (<Card.Group itemsPerRow={cardsPerView}>
              {cards}
              </Card.Group>)
              }
              </Grid.Column>
              </Grid>
          );
    }


}

export const ArticlePlaceholder = (key) => (
    <Card key={key}
          href="/setup"
          style={{maxHeight:"70vh",
                  maxWidth:"15em"}} >
      <Card.Content>
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />

        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
      </Card.Content>
      <Card.Content extra textAlign="left">
        intrgr.com
      </Card.Content>
    </Card>
);


export default RecommendedContent;
