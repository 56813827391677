import React, { Component } from 'react';
import { Responsive, Grid, Icon, Button,Segment, Menu, Header, Container} from 'semantic-ui-react';
import styled from 'styled-components';
import Futura from '../fonts/FUTURISE.ttf'
import ArticleInput from './ArticleInput';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

const Font = styled.span`
             font-family: Futura, arial;
             src: url(${Futura});
            `;
const FixLink = styled.span`
             margin: auto;
            `;

class SiteMenu extends Component {

  handleClick = (e, {name}) => name === 'Login' 
                                    ? this.props.history.push("/login")
                                    : 
                                    name === "Sign Up" 
                                    ? this.props.history.push("/signup") 
                                    : null;

  render() {
    const { authed } = this.props;
    const {pathname} = this.props.location;
    const loginBool = pathname === "/signup" || pathname === "/"; 
    return (
      <>
      <Responsive {...Responsive.onlyComputer}>
      <Menu widths={2} size='mini' borderless style={{marginBottom:'1%'}}>
      <Grid container centered columns={3} padded >
      <Grid.Column width={3} style={{paddingBottom:'0em'}}>
      <Link to="/" style={{float:'right'}}>
      <Header as='h1' color="pink">
      <Font>
      intrgr
      </Font>
      </Header>
      </Link>
      </Grid.Column>
      <Grid.Column width={10} style={{paddingLeft:'1em', paddingRight:'1em'}}>
       <ArticleInput />
      </Grid.Column>
      <Grid.Column floated='right' width={3} style={{paddingBottom:'0em'}}>
      <Menu.Menu size='huge' position='right' style={{paddingBottom:'0em'}}>
      {
       (() => {
          if(!authed) 
           return (
            <>
            <Button style={{margin: 'auto'}} basic name="Sign Up" color="pink" onClick={this.handleClick}>
             Sign Up
            </Button>
            <Button style={{margin: 'auto'}} basic name="Login" color="pink" onClick={this.handleClick}>
           Login
            </Button>
           </>
            );
         else
           return (
                   <Link as="a" to='/profile'>
                   <Icon link inverted color='pink' size='large' circular name='user'/>
                   </Link>
                  );
          })()
      }
      </Menu.Menu>
      </Grid.Column>
      </Grid>
      </Menu>
      </Responsive>
      <Responsive {...Responsive.onlyMobile}>
      <Menu size='mini' borderless>

     { this.props.prevLink ? 
      (<Menu.Item >
      <Link as="a" to={this.props.prevLink}>
        <Icon style={{padding:'0', marginLeft:'0',}} size='big' name='angle left' />
      </Link>
      </Menu.Item>)
         : 
      (<Menu.Item >
      <Link to="/">
      <Header as='h1' color="pink">
      <Font>
      intrgr
      </Font>
      </Header>
      </Link>
      </Menu.Item>)
     }

      <Menu.Menu position='right' style={{paddingRight:'.5em'}}>
      {
       (() => {
          if(!authed) 
           return (
            <>
            <Menu.Item style={{padding:'.25em', paddingLeft:'0'}}>
            <Button size='large' basic name="Sign Up" color="pink" onClick={this.handleClick}>
             Sign Up
            </Button>
            </Menu.Item>
            <Menu.Item style={{padding:'.25em'}}>
            <Button size='large' basic name="Login" color="pink" onClick={this.handleClick}>
           Login
            </Button>
            </Menu.Item>
           </>
            );
         else
           return (
                <>
                <FixLink>
                 <Link>
                   <Icon.Group size='big'>
                    <Icon link color='pink' name='send' />
                    <Icon corner='bottom right' name='circle thin' color='gray' />
                   </Icon.Group>
                 </Link>
                 </FixLink>
                  <FixLink>
                  <Link to='/vlog' style={{paddingLeft:'1em'}}>
                  <Icon.Group size='big'>
                   <Icon link color='pink' name='camera' />
                   <Icon corner='bottom right' name='add' color='gray' />
                  </Icon.Group>
                  </Link>
                  </FixLink>
                  <FixLink>
                   <Link to='/profile' style={{paddingLeft:'.5em'}}>
                   <Icon link inverted color='pink' size='large' circular name='user'>
                   </Icon>
                   </Link>
                  </FixLink>
                  </>
                  );
          })()
      }
      </Menu.Menu>
      </Menu>
      </Responsive>
      </>
    );

  }


}

export default SiteMenu = withRouter(SiteMenu);
