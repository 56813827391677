import axios from 'axios';
import { API } from '../config';
export default function getRelated (content_url_token) {
   return axios.get(`${API}/content/${content_url_token}/related`).then( (response) => {

       if(response.status === 200)
           return response.data.candidates; 

       return [];

   });
}
