import axios from 'axios';

const handleS3PresignedPost = ({fields, url}, file) => {
    const headers = {headers: {'Content-Type': 'multipart/form-data'}};
    const formData = new FormData();
    formData.append("Content-Type", file.type);

    Object.keys(fields).forEach( (field) => {
        formData.append(field, fields[field]);
        
    });
    formData.append("file", file);
    return axios.post(url, formData, headers);
};


export default function uploadVideo(AxiosClient, file, metadata) {
   return AxiosClient.get(`profile/vlog/upload?fileType=${file.type}`).then( async ({data}) => {
       const { urls } = data;
       const textFile = new File([JSON.stringify(metadata)], "metadata.json", {type: 'application/json; charset=utf-8'});
       const descPromise = handleS3PresignedPost(urls[0], textFile);
       const filePromise = handleS3PresignedPost(urls[1], file);
       const promises = await Promise.all([descPromise, filePromise]);
       return promises;
   });
}



