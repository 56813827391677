import React, { Component } from 'react'; 
import styled from 'styled-components';
import { Container, Header, Transition, Grid } from 'semantic-ui-react';


class SubSignUp extends Component {

  state = {visible: false};

  componentDidMount () {
    setTimeout(this.setState({visible: true}), 3000);
  }

  render () {
    const { visible } = this.state;
    return (
    <>
    <Header as='h2' style={{textAlign:'center'}}>
      Over 100,000 new articles curated just for you.
    </Header>
      <Transition visible={visible} animation='fade' duration={3000}>
    <Header as='h2' style={{marginTop:'0', textAlign:'center'}}>
       Everyday.
    </Header>
    </Transition>
    </>
    );

  }


}

export default SubSignUp;
