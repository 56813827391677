import React, { Component, useState, useEffect } from 'react'; 
import { Redirect, Link } from 'react-router-dom';
import { Input, Modal, Icon, List, Header, Image, Segment, Container, Item, Button, Form, Message, TextArea } from 'semantic-ui-react';

import styled from 'styled-components';
import {withRouter} from 'react-router';
import { API } from '../config';
import axios from 'axios';
const DEFAULT_IMG='https://react.semantic-ui.com/images/wireframe/square-image.png';

const VerticalScroll = styled.div`
    .fix {
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
    flex: 0 0 auto;
   }
`;

export default function SendMessageModal (props) { 
  const isAuthed = !!localStorage.getItem("authToken");

  const [message, setMessage] = useState("");
  const [recipient, setRecipient] = useState("");
  const [errors, setErrors] = useState({});
  const [authMsg, setAuthMsg] = useState(false);
  const [messageList, setMessageList] = useState(null);
  const [success, setSuccess] = useState();
  const [failureCode, setFailureCode] = useState();
  const [validated, setValidated] = useState(true);

  const inputs = [message, recipient];
  const mapInput= {"message": setMessage, "recipient": setRecipient};

  const handleChange = (e, {name, value} ) => { 
    mapInput[name](value);
  }

  const getInbox = () => {
    const { axiosClient } = props;
    axiosClient.get('profile/message').then( ({data}) => {
      setMessageList(data.messages);
    });
  };

  const validators = { 
    message: () => {
      if (!message) { return undefined;}
      if (message.length > 1000) {
        return "Your message has to be less than 1000 characters";
      }
      return undefined;
    },
    badResponse: () => {
      if (failureCode == 422) {
        return 422;
      }
      return undefined;
    },
    recipient: () => {
      return undefined;
    }
  };

  const getFieldError = function (field) {

    if (!errors[field])  
      return null;

    return { content: errors[field],
             pointing: "below"
            };

  };

  const htmlDecode = function(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  const createListItem = ({username, profileToken, hasThumb, text}) => {
    console.log('test');
    const imageUrl = !hasThumb ? DEFAULT_IMG
                : `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/thumbnail.png`;

    return (<List.Item as="a" style={{padding:'1em', paddingRight:'0'}}>
      <Image size='small' style={{maxHeight:'3em', maxWidth:'3em'}} avatar src={imageUrl} />
      <List.Content style={{paddingLeft:'1em', width:'88%'}}>
      <Link as="span" to={{pathname:`/inbox/${username}`, state: {thumbUrl: imageUrl,
                                                                  prevLink:'/inbox'}}} >
        <List.Header style={{paddingBottom:'.25em'}}>
    <div style={{display:'flex',
             alignItems:'center',
          justifyContent:'space-between'}}>
            
    <div>
    <p>
        {username}
    </p>
    </div>
      <div style={{display:'flex', alignItems:'center'}}>
      <List.Description >
       <p style={{fontWeight:'lighter' }}>
        3 days ago
      </p>
      </List.Description>
      <div>
        <Icon size='large' name='angle right' style={{marginLeft:'.15em'}} color='black' />
      </div>
      </div>
    </div>
    </List.Header>
        <List.Description style={{paddingBottom:'.77em'}}>
          {htmlDecode(text)}
        </List.Description>
    </Link>
      </List.Content>
    </List.Item>);
  };

  useEffect( () => {

      const errors = {};
      Object.keys(validators)
            .forEach( (key) => {
                const error = validators[key]();
                if( error ){
                    errors[key] = error;
                }
             });

      setErrors(errors);
  }, [message, recipient, validated, failureCode]);

 useEffect( () => {

   if(messageList === null)
     getInbox();

 }, [messageList]);



  const createErrorMessage = function() {

      if (authMsg) {
          return (<Message floating info>
              {<a style={{color:"#E03997"}} href='/signup'>Sign Up</a>} or {<a style={{color:"#E03997"}} href='/login'>Login</a>} to post a comment 
                  </Message>)
      }
    if (!Object.keys(errors).length) {
      return null;
    }

    if (errors["badResponse"] == 422) {
          return (<Message floating info>
              {<Link to="/profile" style={{color:"#E03997"}}>Click here</Link>} to setup your profile to send messages.
                  </Message>)
    }

   
    let errorMsg = "Seems like something went wrong.";

    errorMsg = errors[ Object.keys(errors)[0] ]

    return (<Message negative>
    <Message.Header> 
        Your message couldn't be sent. 
    </Message.Header>
    {errorMsg}
    </Message>)

  }

  const handleSubmit = function () {
    const { axiosClient } = props;

    if (!isAuthed || !axiosClient) {
        setAuthMsg(true);
        return;
    }

    axiosClient.post(`profile/message/${recipient}`, {
      text: message
  }).then(resp => {
        if ( !localStorage.getItem("authToken") ) {
            setAuthMsg(true); 
            return;
        }
    setSuccess(true);
        setMessage("");
  }).catch(err => {
        setFailureCode(err.response.status);
    });
  };


  return(
  <>
  <Container style={{paddingTop:'1em'}}>
  <Segment.Group>
  <Segment textAlign='center' 
  style={{
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)'
    }}
  >
    <div className='message-header' style={{
    display: 'flex',
    justifyContent: 'center',
      alignItems: 'center'
     }}>
      <div style={{display:'flex', flex:1, alignItems:'center'}} />
    <div style={{justifySelf:'center'}}>
      <Header as='h2'>
        Messages
      </Header> 
    </div>
      <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'flex-end'}}>
    <Modal
     closeIcon
     trigger={
        <Icon link fitted color='pink' size='large' name='edit' />
    }>
    <Modal.Content>
        <Form size='large' style={{marginBottom:'4em',
                    marginTop:'2em'}}>
            { createErrorMessage() }
           <Form.Field  error={errors['recipient']}>
            <label>To</label>
            <Input 
          transparent
        placeholder="Username"
                 name='recipient'
                 onChange={handleChange}
                 value={recipient} />
            </Form.Field> 
           <Form.Field error={errors['message']}>
            <label>Message</label>
            <TextArea placeholder="Add your message here..."
                 name='message'
                 onChange={handleChange}
                 value={message} />
            </Form.Field> 
            <Form.Button 
        onClick={handleSubmit}
                disabled={errors["message"]}
        basic color='pink' compact floated='right'>
                Send
            </Form.Button>
          </Form>
    </Modal.Content>
    </Modal>
    </div>
    </div>
  </Segment>
 <VerticalScroll>
 <Segment className='fix' basic style={{paddingTop:'0', 
                    overflowY:'scroll',
                    height:'80vh',
                    flexDirection:'column',
                    paddingLeft:'.25em', 
                    paddingRight:'.25em'}}>
  <List celled style={{paddingBottom:'3em'}}>
    {messageList !== null ? messageList.map(createListItem) : null}
  </List>
  </Segment>
  </VerticalScroll>
  </Segment.Group>
  </Container>

</>
  );

};
