import axios from 'axios';
import { API } from '../config';
export class AxiosClient {
        constructor(authToken){
            this.authToken = authToken;
            this.headers = authToken ? {"Authorization" : `Bearer ${authToken}`} : {};
        }


        get(url) {
            return axios.get(`${API}/${url}`, 
                             {headers: this.headers}).catch( (err) => {
                                  if(err.response.status == 401)
                                    localStorage.removeItem("authToken");
                                 return err;
                             });
        }

        post(url, body) {
            return axios.post(`${API}/${url}`, 
                              body,
                              {headers: this.headers}).
                              catch( (err) => {
                                  if(err.response.status == 401)
                                    localStorage.removeItem("authToken");
                                  if(err.response.status == 422) {
                                    throw err;
                                  }
                                  return err;
                              });
        }

        formUpload(url, formData) {
            const headers = Object.assign({'Content-Type': 'multipart/form-data'},
                                          this.headers);
            return axios({
                 method: 'post',
                 url: `${API}/${url}`,
                 data: formData,
                 headers}).catch( (err) => {
                   if(err.response.status == 401)
                     localStorage.removeItem("authToken");
                   return err;
                 });
         }

}

export function getAxiosClient() {
  const authToken = localStorage.getItem("authToken");

  return new AxiosClient(authToken);

}
