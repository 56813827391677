import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css'; 
import App from './App';
import 'swiper/css/swiper.css';
import Article from './Article';
import Transfer from './Transfer';
import Home from './Home';
import Landing from './Landing';
import ProfileView from './components/ProfileView'; 
import Login from './Login';
import Common from './components/Common'; 
import VideoView from './components/VideoView'; 
import VideoCommentView from './components/VideoCommentView'; 
import VideoUploadView from './components/VideoUploadView'; 
import SendMessageView from './components/SendMessageView';
import MessageView from './components/MessageView';
import getVideo from './components/utils/getVideo'; 
import Setup from './components/Setup';
import ReactGA from 'react-ga';
import { getAxiosClient } from './utils/AxiosClient';
import AuthContext from './auth-context';

export default function Routes (props) {
    ReactGA.initialize('UA-167521035-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

	const [axiosClient, setAxiosClient] = useState( getAxiosClient() );
    const authed = localStorage.getItem("authToken");
    const { prevLink } = props.location && props.location.state ? props.location.state : {};

    return (<Router>

            <Switch>

            <Route exact path="/">
              <AuthContext.Provider value={{axiosClient, authed}}>
              <VideoView AxiosClient={getAxiosClient()}/>
              </AuthContext.Provider>
            </Route>

            <Route exact path="/login">
            <Common>
              <Landing login="true" />
            </Common>
            </Route>

            <Route exact path="/signup">
            <Common>
              <Landing login={false}/>
            </Common>
            </Route>

            <Route exact path="/profile" 
              render={(props) => (
                <Common authed={localStorage.getItem("authToken")}>
                <ProfileView key={Date.now()} axiosClient={getAxiosClient()} isOwner={true} />
                </Common>
              )}
            />

            <Route exact path="/p/:username" render={(props) => (
                <Common authed={localStorage.getItem("authToken")} prevLink={props.location.state ? props.location.state.prevLink : 
                                                                             undefined}>
                <ProfileView key={Date.now()} axiosClient={getAxiosClient()}/>
                </Common>
              )}
            />

            <Route exact path="/inbox" render={(props) => (
                <Common axiosClient={getAxiosClient()} prevLink={props.location.state ? props.location.state.prevLink : undefined}>
                <SendMessageView key={Date.now()} axiosClient={getAxiosClient()}/>
                </Common>
              )}
            />
            <Route exact path="/inbox/:username" render={(props) => {
                const locationObject = props.location.state ? props.location : props.history.location;
                const { username } = props.match.params;
                const { thumbUrl } = locationObject.state;
                const prevLink = locationObject.state ? locationObject.state.prevLink : undefined;
                console.log('hey', thumbUrl);
                return (<Common axiosClient={getAxiosClient()}  prevLink={prevLink}>
                <MessageView prevLink={prevLink} thumbUrl={thumbUrl} key={Date.now()} username={username} axiosClient={getAxiosClient()}/>
                </Common>);
              }
              }
            />

            <Route exact path="/v/comment" render={(props) => (
                <Common prevLink='/v/lol' authed={localStorage.getItem("authToken")}>
                <VideoCommentView key={Date.now()} axiosClient={getAxiosClient()}/>
                </Common>
              )}
            />

            <Route path="/v/:token" render={(props) => {
              const locationObject = props.location.state || {};
              const { username } = locationObject;
              const { token } = props.match.params;

              return(<VideoView axiosClient={getAxiosClient()} videoInView={token} username={username}/>);
              }
            }/>

            <Route path="/vlog" render={() => <VideoUploadView axiosClient={getAxiosClient()} />}/>

            <Route path="/setup">
            <Common authed={localStorage.getItem("authToken")}>
              <Setup />
            </Common>
            </Route>

            <Route path="/a/:name"component={Article} />
            <Route path="/:url" component={Transfer} />

                

            </Switch>

            </Router>);

}
