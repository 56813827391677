import React, { Component } from 'react'; 
import { Redirect, Link } from 'react-router-dom';
import { Modal, Popup, TextArea, Input, Header, Grid, Label, Container, Image, Segment, Icon, Form, Button, Message} from 'semantic-ui-react';
import styled from 'styled-components';
import {withRouter} from 'react-router';
import { API } from '../config';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SignUp from './SignUp';
axios.defaults.withCredentials = false;
axios.defaults.crossDomain = true;

const VALID_MIMES = new Set(["image/png", "image/jpg", "image/jpeg"]);
class ProfileCard extends Component { 

  constructor (props) {
      super(props)
      const { firstName, lastName, aboutMe, profileToken } = this.props;
      this.state = {
          firstName,
          lastName,
          profileToken,
          modalState: true,
          editMode: false,
          justFollowed: null,
          file: null,
          imgSrc: null,
          changed: false,
          errors: {},
          validated: false,
          success: undefined,
          errorCode: null, 
          submitted: false
      };

      this.validators = { 
        firstName: () =>  { 
             const regex = /[^a-zA-Z]/;
             return (!this.state.firstName || 
                     this.state.firstName.match(regex) )  ? 
                     "Please enter your first name." :
                     undefined;
        },
        lastName: () =>  { 
             const regex = /[^a-zA-Z]/;
             return (!this.state.lastName || 
                     this.state.lastName.match(regex) )  ? 
                     "Please enter your last name." :
                     undefined;
        },
        file: () => {
          if (!this.state.file) { return undefined; }
          const { type, size } = this.state.file;
          if (!VALID_MIMES.has(type)) {
            return "Valid file types are jpeg, jpg, or png";
          }

          if ( size >= (3.2 * 10**6) ) {
            return "The max file size is 3MB";
          }
          return undefined;
        },
        aboutMe: () => {
          if (!this.state.aboutMe) { return undefined;}
          if (this.state.aboutMe.length > 240) {
            return "Your About Me section has to be less than 240 characters"
          }
          return undefined;
        }
      };
  }


  fileChange = e => {
  if (this.state.imgSrc) 
    URL.revokeObjectURL(this.state.imgSrc);
  const file = e.target.files[0];
    this.setState({ file, 
          imgSrc: URL.createObjectURL(file),
                    changed: true,
                    success: undefined,
          validated: false, 
          errorCode: null}, () => {
    
      console.log("File chosen --->", this.state.file);
    });
  };

  handleChange = (e, {name, value} ) => this.setState({[name]: value,
                                                       changed: true,
                                                       success: undefined,
                                                       validated: false,
                                                       errorCode: null});

  handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const { axiosClient } = this.props; 
    const { aboutMe, firstName, lastName, file } = this.state;
    if (firstName)
      formData.set('firstName', firstName);
    if (lastName)
      formData.set('lastName', lastName);
    if (aboutMe)
      formData.set('aboutMe', aboutMe);

    if (file) 
        formData.set('file', file);

    axiosClient.formUpload('profile', formData)
         .then( (resp) => { 
                    this.setState({success: true,
                                   imgSrc: null,
                                   file: null,
                                   thumbUploaded: file ? true : false,
                                   editMode: false,
                                   changed: false,
                                   loading: false});
                    window.location.reload();
                }).catch( (error) => {
                    const { response } = error;
                    this.setState({success: false,
                                   editMode: false,
                                   loading: false,
                                   errorCode: response});
                 });

      if(this.state.success === undefined)
        this.setState({loading: true});
  }

  getFieldError (field) {
    const { errors } = this.state;

    if (!errors[field])  
      return null;

    return { content: errors[field],
             pointing: "below"
            };

  }

  componentWillUnmount() {
     URL.revokeObjectURL(this.state.imgSrc);
  }

  componentDidMount() {

      const { aboutMe, firstName, lastName, profileToken, hasThumb } = this.props;
      this.setState({firstName, 
                       lastName, 
                       aboutMe});
  }

  componentWillReceiveProps(nextProps) {
    if(!this.state.firstName || !this.state.lastName) {
      const {firstName, lastName, aboutMe} = this.props;
      this.setState({firstName, lastName, aboutMe});
    }
  }

  componentDidUpdate() {
      if(this.state.validated)
          return;

      if(this.state.success)
        return (<Redirect to='/profile'/>);

      const errors = {};
      const check_fields = {}
      Object.keys(this.validators)
            .filter( (key) => this.state[key] )
            .map( (key) => {
                const error = this.validators[key]();
                if( error ){
                    errors[key] = error;
                }
             });

      this.setState({errors: errors,
                     validated: true});
  }

  fileInputRef = React.createRef();
 
  fileUpload(e) {
  if(e) 
      e.preventDefault();
    this.fileInputRef.current.click();
  }; 


  createErrorMessage() {
    const { errorCode, errors } = this.state;
    if (!Object.keys(errors).length && !errorCode) {
      return null;
    }
  
  let errorMsg = "Seems like something went wrong.";
  if(errorCode === 409)
      errorMsg = "Oops weird"

  if (errors["file"]) {
    errorMsg = errors["file"]
  } else if (Object.keys(errors).length) {
    errorMsg = errors[ Object.keys(errors)[0] ]
  }

    return (<Message 
      size='mini'
       style={{fontSize:'.4em'}}
       negative 
       header="Oops, we can't update your profile" 
       content={errorMsg}
      />)
  }

  profileUploadText() { 
    let text="Upload a profile picture";

    if (this.state.file) {
      const { name } = this.state.file;
      text = name < 20 ? name : `${name.substring(0,20)}...`
    }

    return this.state.file ? 
          (<span style={{fontWeight:'10'}}>{text}</span>) :
             text 
  }

  profileImagePreview() {
  const { imgSrc, errors } = this.state;
  const { profileToken, hasThumb } = this.props;
  const previewCond = (imgSrc && !errors['file']);
  let imageUrl = '../../../assets/user_placeholder.png';

  if (previewCond) {
    imageUrl = imgSrc;
      return (<Image src={imageUrl} 
              style={{maxWidth:'8em', minWidth:'5em', margin:'auto'}}
              size='tiny' />);
  } 

    if (hasThumb) 
       imageUrl = `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/thumbnail.png`;
    return (<Image src={imageUrl} size='small' style={{maxWidth:'8em', minWidth:'5em', margin:'auto'}}circular />);
  }

  handleOpen() {
    this.setState({isOpen:true});
    const timeoutLength = 230;
    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false })
    }, timeoutLength)
 
  }

  handleFollow(action) {
    const { axiosClient, profileToken } = this.props; 
    switch(action) {
    case("follow"):
      axiosClient.post(`profile/${profileToken}`, {action:'follow'}).then( (data) => {
        this.setState({justFollowed: true});
      });
      break;
    case("unfollow"):
      axiosClient.post(`profile/${profileToken}`, {action:'unfollow'}).then( (data) => {
        this.setState({justFollowed: false});
      });
      break;
    default:
      break;
    }
  }

  render () {
    const { justFollowed, editMode, thumbUploaded } = this.state;
    const { isuser, isFollowed, profileToken, lastName, firstName, isOwner, aboutMe, hasThumb } = this.props;
    if (!editMode) {
      const imageUrl = (hasThumb || thumbUploaded) ? `https://intrgr-profiles.s3-us-west-1.amazonaws.com/${profileToken}/thumbnail.png`
                                                   : '../../../assets/user_placeholder.png';
      return (
         <Segment.Group horizontal style={{paddingBottom:'0'}}>
         <Segment padded basic style={{maxWidth:'20%', marginRight:'0', marginLeft:'0'}}>
        {
          <Image src={imageUrl} size='small' style={{maxWidth:'8em', 
                                                     minWidth:'5em', 
                                                     marginRight:'0'}} circular />
        }
         </Segment>
         <Segment basic style={{borderLeftStyle: 'none', maxWidth:'60%', margin:'auto', marginLeft:'0', minWidth:'8em'}}>
          <Header as='h2' textAlign='left' style={{marginTop:'0'}}>
            {`${this.state.firstName && this.state.success ? this.state.firstName : (firstName ? firstName : 'Firstname')} ${this.state.lastName && this.state.success ? this.state.lastName : (lastName ? lastName : 'Lastname')}`}
            <Popup
                content="Copied!"
                on='click'
                trigger={
                    (<CopyToClipboard text={`https://intrgr.com/p/${this.props.profileToken}`}>
                        <Icon 
                        style={{fontSize:'.6em', marginLeft:'.15em', marginBottom:'.66em'}} 
                        size='tiny' 
                        onClick={this.handleOpen.bind(this)} 
                        link 
                        name='copy outline' 
                        color='pink' />
                    </CopyToClipboard>)
                }
                open={this.state.isOpen}
                onOpen={this.handleOpen}
                position='top center'
            />
          </Header>
          <Header as='h4' textAlign='left' style={{marginTop:'0', fontWeight:'normal'}}>
            { aboutMe ? aboutMe : 
              "This user doesn't have a bio yet."
            }
          </Header>
         </Segment> 
        <Segment style={{maxWidth:'10%', borderLeftStyle:'none', marginRight:'.45em'}}>
         {
         !localStorage.getItem("authToken") ? 
          (<Modal
            size="mini"
            trigger={<Button floated='right'
                      basic name="Follow"
                      content="Follow"
                      color="pink"
                      />}
           >
           <Modal.Header style={{textAlign:'center'}}>
            Sign Up or {<Link to="/login">Login</Link>} to follow your favorite people
           </Modal.Header>
           <Modal.Content style={{textAlign:'center', padding:'10%'}}> 
            <SignUp style={{margin:'auto'}}/>
           </Modal.Content>
           </Modal>)
           :
         (<Button floated='right' 
                 basic name="Follow" 
                 color="pink"
                 onClick={isOwner ? () => this.setState({editMode: true}) :
                    ( ( (isFollowed && justFollowed !== false) || justFollowed) ? () => this.handleFollow.call(this, "unfollow")
                                 : () => this.handleFollow.call(this, "follow") )
                 }
                 content={isOwner ? "Edit" : 
                   ( ( (isFollowed && justFollowed !== false) || justFollowed) ? "Unfollow" : "Follow")
                 } 
         />)
       }
        </Segment>
         </Segment.Group>
      );
    }
    if ( this.state.editMode )
      return (
        <Segment.Group horizontal>
        <Segment padded basic style={{maxWidth:'25%', minWidth:'12em', paddingRight:'0'}}>
      <Form style={{margin:'auto'}}
        size='mini' success={this.state.success} onSubmit={this.handleSave} loading={this.state.loading} warning={false}>
           {this.profileImagePreview.call(this)}
           <Form.Field>
             <Button as='div' size='mini' labelPosition='left' style={{marginTop:'10%'}}
                 onClick={this.fileUpload.bind(this)} >

                 <Label as='p' basic style={{borderRightStyle:'hidden', margin:'auto', minWidth:'90%'}}>
                  {
                    this.profileUploadText()
                  }
                  </Label>
                 <Button size='mini' basic style={{marginRight:'0'}}>
                   <Icon name='camera' style={{margin:'0'}}color='pink'/>
                 </Button>
              </Button>
              <input ref={this.fileInputRef} hidden onChange={this.fileChange} name='profilePic' type='file' />
            </Form.Field>
            </Form>
            {
              this.createErrorMessage()
            }
        </Segment>
           <Segment padded="very" basic style={{margin:'auto', borderLeftStyle: 'none', maxWidth:'55%'}}>
            <Form style={{maxWidth:'75%', margin:'auto'}}size='mini' success={this.state.success} onSubmit={this.handleSave} loading={this.state.loading} warning={false}>
            <Form.Field error={this.getFieldError('firstName')}>
              <label>First Name</label>
              <Input placeholder='Firstname'
                          name='firstName'
                          onChange={this.handleChange}
                          type='firstname'
                          value={this.state.firstName}
                          error={this.getFieldError('firstName')}
                          />
            </Form.Field> 
            <Form.Field error={this.getFieldError('lastName')}>
              <label>Last Name</label>
              <Input placeholder='Lastname'
                          name='lastName'
                          onChange={this.handleChange}
                          type='lastname'
                          value={this.state.lastName}
                          error={this.getFieldError('lastName')}
                          />
            </Form.Field> 
              <Form.Field error={this.getFieldError('aboutMe')}>
                 <label>About Me</label>
                <TextArea placeholder="Write up to 240 characters about yourself..."
                       name='aboutMe'
                       onChange={this.handleChange}
                       value={this.state.aboutMe}
                       error={this.getFieldError('aboutMe')}  />

              </Form.Field> 
          </Form>
          </Segment>
          <Segment style={{maxWidth: '20%',
                           borderLeftStyle:'none'}}>
           <Button floated='right' 
                   disabled={!this.state.changed || Object.keys(this.state.errors).length !== 0}
                   onClick={this.handleSave}
                   basic name="save" 
                   color="pink"
                   content="Save"
           />
           <Button size='mini' style={{margin:'auto', marginRight:'.25em', marginTop:'1em'}} floated='right' 
                   onClick={() => { 
                      const { firstName, lastName, aboutMe } = this.props;
                      const { success } = this.state;
                      this.setState({
                        firstName: success && this.state.firstName ? this.state.firstName : firstName,
                        lastName: success && this.state.lastName ? this.state.lastName : lastName,
                        aboutMe: success && this.state.aboutMe ? this.state.aboutMe : aboutMe,
                        editMode: false
                      });
                     } 
                   }
                   basic name="cancel" 
                   content="Cancel"
           />
          </Segment>
        </Segment.Group>
      );
  }


}


export default ProfileCard;
